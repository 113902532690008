import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import HomeIcon from "@material-ui/icons/Home";
import { RefLink } from "../../common/anchor";
import Link from "@material-ui/core/Link";
import AboutBox from "../common/about";
import Footer from "../common/footer";
import { readytoreceive } from "../common/texts";

export default class AboutZh extends React.Component<{}, {}> {
  render() {
    window.document.title =
      "About ReceiveSMSForFree | Free valid SMS Numbers" + readytoreceive;
    return (
      <Container>
        <Link
          component={RefLink}
          to={"/zh/"}
          style={{ marginBottom: "10px", display: "block" }}
        >
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            startIcon={<HomeIcon />}
          >
            Back to Top
          </Button>
        </Link>
        <Typography variant="h3" component="h1" align="center">
          ReceiveSMS
          <br />
          ForFree
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom align="center">
          关于这个网站
        </Typography>
        <div style={{ padding: "10px", margin: "10px 0" }}>
          <Typography variant="h5" component="h3" gutterBottom>
            什么是ReceiveSMSForFree？
          </Typography>
          <p>
            ReceiveSMSForFree是最好的免费服务，可在线接收您的消息。
            <br />
            我们在10多个国家/地区拥有SMS号码，而且我们还在不断增长。
          </p>
          <p>
            通过使用ReceiveSMSForFree，您可以保护自己免受任何企图窃取您的个人信息的公司或垃圾邮件的侵害。
            <br />
            我们为您提供一个完全免费的一次性SMS号码，以便您可以使用Twitter，Google，Facebook，Instagram等服务。
          </p>
        </div>
        <div style={{ padding: "10px", margin: "10px 0" }}>
          <Typography variant="h5" component="h3" gutterBottom>
            这项服务免费吗？
          </Typography>
          <p style={{ color: "#ff0000" }}>
            <strong>它是完全免费的。</strong>
          </p>
          <p>此服务仅用于学术目的，没有任何计费系统。</p>
          <p>
            我们通过广告收入来维持和运营我们的服务，而广告收入将成为所有新电话号码的来源。
          </p>
        </div>
        <AboutBox />
        <div style={{ padding: "10px", margin: "10px 0" }}>
          <Typography variant="h5" component="h3" gutterBottom>
            我们使用的技术
          </Typography>
          <p>
            我们使用Golang，zh​​va，C ++和zhvaScript之类的语言来打开此站点。
          </p>
          <p>
            我们使用虚拟电话技术来有效地接收和管理到达我们托管电话号码的每条消息。
            <br />
            利用这种技术，可以将交付的SMS集中在一台集中式服务器上，格式化为统一格式并存储在SQL
            Server上。
          </p>
          <p>该系统具有三个优点：</p>
          <ul>
            <li>添加新的电话号码非常容易。</li>
            <li>所有程序都是自动化的。</li>
            <li>您可以在世界各地稳定使用我们的服务。</li>
          </ul>
          <p>
            该服务的字面意思是“两只鸟一块石头”-因为我们可以推动研究前进，我们的用户不受电话号码的限制，并且拥有自由。
          </p>
        </div>
        <div style={{ padding: "10px", margin: "10px 0" }}>
          <Typography variant="h5" component="h3" gutterBottom>
            Our goal
          </Typography>
          <p>
            1. We are researching entirely new personal authentication
            infrastructures that replace traditional SMS authentication.
            <br />
            With this project, we are looking for the vulnerability of
            traditional SMS authentication and forming the basis of a new
            personal authentication systems.
          </p>
          <p>
            2. We are conducting researches to improve the comprehensiveness of
            mobile networks.
            <br />
            By making everyone use our services, we are looking for clues to
            improve the network availability and load tolerance of mobile
            networks.
          </p>
        </div>
        <Footer />
      </Container>
    );
  }
}
