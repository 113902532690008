import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { RefLink } from "../../common/anchor";
import Link from "@material-ui/core/Link";
import AboutBox from "../common/about";
import { fetcher } from "../../common/fetch";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Footer from "../common/footer";
import Buttons from "../common/buttons";
import AdsCard from "../../common/ad";

export default class ServiceIndexZh extends React.Component<
  {
    inactive: boolean;
  },
  {
    services: any[];
    loading: boolean;
  }
> {
  constructor(props: { inactive: boolean }) {
    super(props);
    this.state = {
      services: [],
      loading: true,
    };
  }

  async componentDidMount() {
    try {
      const res = await fetcher("getPhoneByServices", {
        active: 1,
      });
      const r = await res.json();
      if (!r.error) {
        this.setState({
          services: r.body,
          loading: false,
        });
      } else {
        throw new Error("e");
      }
    } catch (e) {
      this.setState({
        services: [],
        loading: false,
      });
    }
  }

  render() {
    const { services, loading } = this.state;
    window.document.title =
      "按国家显示电话号码 - ReceiveSMSForFree | 免费使用短信服务";
    return (
      <Container>
        <Typography variant="h3" component="h1" align="center">
          免费使用短信服务
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom align="center">
          完全免费的一次性SMS
        </Typography>
        <Buttons />
        <AdsCard />
        <Typography variant="h6" component="h6" style={{ textAlign: "center" }}>
          通过来电服务搜索
        </Typography>
        {!loading && (
          <Grid container style={{ margin: "20px 0" }} justify="space-between">
            {services.map((item: any) => {
              return (
                <Grid item xs={12} sm={6} md={4} style={{ margin: "5px 0" }}>
                  <Card
                    key={item.country}
                    style={{
                      width: "100%",
                      background: "transparent",
                      boxShadow: "0 0 0 0",
                    }}
                  >
                    <CardContent>
                      <Grid container alignItems={"center"}>
                        <Grid item xs={9}>
                          <Typography color="textSecondary" gutterBottom>
                            {item.message_from}
                          </Typography>
                          <Typography variant="h5" component="h2">
                            <Link
                              component={RefLink}
                              to={"/zh/service/" + item.message_from}
                            >
                              收到 {item.SUM} 条消息
                            </Link>
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )}
        {loading && (
          <div>
            <CircularProgress />
          </div>
        )}
        <Grid container>
          <Grid
            xs={12}
            sm={12}
            md={6}
            item
            style={{ margin: "10px 0", padding: "10px" }}
          >
            <Typography variant="h5" component="h3" gutterBottom>
              信任和记录
            </Typography>
            <p>
              自从2019年开始进行演示测试以来，我们已经被很多人使用。
              <br />
              自服务开始以来，我们一直在提供稳定可靠的电话号码，每个人都对其进行了高度评价。
            </p>
            <p>
              我们期待您的反馈，以便我们可以继续改善我们的服务。
              <br />
              随时发送改进建议至sms-online-contact@protonmail.com。
            </p>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={6}
            item
            style={{ margin: "10px 0", padding: "10px" }}
          >
            <Typography variant="h5" component="h3" gutterBottom>
              有问题吗？
            </Typography>
            <p>
              如果您对我们的服务有疑问，请随时与我们联系（sms-online-contact@protonmail.com）。
            </p>
            <p>
              或者，
              <Link component={RefLink} to={"/faq/"}>
                请参阅常见问题页面
              </Link>
              。
            </p>
          </Grid>
        </Grid>
        <AboutBox />
        <Footer />
      </Container>
    );
  }
}
