import React from "react";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import HomeIcon from "@material-ui/icons/Home";
import { RefLink } from "../../common/anchor";
import Link from "@material-ui/core/Link";
import Footer from "../common/footer";

export default class FAQJa extends React.Component<{}, {}> {
  render() {
    window.document.title =
      "よくある質問 - ReceiveSMSForFree | 無料で使える使い捨てSMSサービス";
    return (
      <Container>
        <Link
          component={RefLink}
          to={"/ja/"}
          style={{ marginBottom: "10px", display: "block" }}
        >
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            startIcon={<HomeIcon />}
          >
            Back to Top
          </Button>
        </Link>
        <Typography variant="h3" component="h1" align="center">
          ReceiveSMS
          <br />
          ForFree
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom align="center">
          よくある質問
        </Typography>
        <Paper style={{ padding: "10px", margin: "10px 0" }}>
          <Typography variant="h5" component="h3" gutterBottom>
            このサイトは無料ですか？
          </Typography>
          <p style={{ color: "#ff0000" }}>
            <strong>はい、このサイトは完全に無料です。</strong>
          </p>
          <p>
            学術目的により運営され、利用料金を徴収するシステムは一切搭載されていません。
            <br />
            本サイトは広告収入によって維持され、広告収入は新たな電話番号を取得するために利用されます。
          </p>
          <Typography variant="h5" component="h3" gutterBottom>
            メッセージはフィルタされていますか？
          </Typography>
          <p>
            はい。多くの方に快適にご利用いただくため、法に触れる内容、危険な内容、いたずら、暴力的であったり、差別を助長するコンテンツ、スパムの可能性が高いメッセージについて、メッセージの処理過程でフィルタリングしています。
          </p>
          <Typography variant="h5" component="h3" gutterBottom>
            しばらく待ちましたが、メッセージが届きません。
          </Typography>
          <p>
            可能な限り素早くメッセージをサイト上に反映できるよう取り組んでいますが、技術的成約があるため、最大5~10分配信に遅延が生じる場合があります。
          </p>
          <p>
            もし、待てどもメッセージが届かない場合、次のような理由が考えられます。
          </p>
          <ul>
            <li>
              電話番号がお使いのサービスからBANされている可能性があります。
            </li>
            <li>
              電話番号が私達のネットワークから切り離されているなど、何らかの問題が生じています。
            </li>
          </ul>
          <p>
            SMSメッセージを再送いただくか、私達のサイトに掲載されている他の電話番号をお使いください。
          </p>
          <Typography variant="h5" component="h3" gutterBottom>
            ReceiveSMSForFreeを支援する方法はありますか？
          </Typography>
          <p>はい、お友達にこのサイトをオススメしてください:)</p>
          <Typography variant="h5" component="h3" gutterBottom>
            電話番号はいつ追加されますか？
          </Typography>
          <p>可能な限り、週1回新たな電話番号を追加できるよう努めています。</p>
        </Paper>
        <Footer />
      </Container>
    );
  }
}
