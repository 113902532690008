import React from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import { withStyles } from "@material-ui/core/styles";
import HomeIcon from "@material-ui/icons/Home";
import PublicIcon from "@material-ui/icons/Public";
import LanguageIcon from "@material-ui/icons/Language";
import HotelIcon from "@material-ui/icons/Hotel";
import HelpIcon from "@material-ui/icons/Help";
import Divider from "@material-ui/core/Divider";
import InfoIcon from "@material-ui/icons/Info";
import { AdBlockDetectedWrapper } from "adblock-detect-react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

const styles = (theme: any) => ({
  root: {
    display: "flex",
  },
  menuButton: {
    marginRight: 20,
  },
  drawerPaper: {
    overflowX: "hidden" as "hidden",
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(7),
    marginTop: theme.spacing(4),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

function HideOnScroll(props: HideOnScrollProps) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

interface HideOnScrollProps {
  children?: React.ReactElement;
  window?: () => Window;
}

class GlobalHeader extends React.Component<
  { classes: any; children: any } & HideOnScrollProps & RouteComponentProps,
  {
    isOpen: boolean;
  }
> {
  constructor(
    props: { classes: any; children: any } & HideOnScrollProps &
      RouteComponentProps
  ) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  toggleNav = () => this.setState({ isOpen: !this.state.isOpen });
  render() {
    const page = this.props.location.pathname.split("/");
    const { isOpen } = this.state;
    const { classes } = this.props;
    let prefix = "";
    if (page.length > 0 && page[1] !== "") {
      if (page[1] === "ja") {
        prefix = "/ja";
      }
      if (page[1] === "zh") {
        prefix = "/zh";
      }
    }
    const drawer = (_isPerment: boolean) => (
      <div>
        <List>
          <Link to={prefix + "/"} onClick={this.toggleNav}>
            <ListItem button>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary={<Typography>Home</Typography>} />
            </ListItem>
          </Link>
          <Link
            to={prefix + "/phone_number_by_countries/"}
            onClick={this.toggleNav}
          >
            <ListItem button>
              <ListItemIcon>
                <PublicIcon />
              </ListItemIcon>
              <ListItemText
                primary={<Typography>Filter by Country</Typography>}
              />
            </ListItem>
          </Link>
          <Link
            to={prefix + "/phone_number_by_services/"}
            onClick={this.toggleNav}
          >
            <ListItem button>
              <ListItemIcon>
                <LanguageIcon />
              </ListItemIcon>
              <ListItemText
                primary={<Typography>Filter by services</Typography>}
              />
            </ListItem>
          </Link>
          <Link to={prefix + "/inactive_numbers/"} onClick={this.toggleNav}>
            <ListItem button>
              <ListItemIcon>
                <HotelIcon />
              </ListItemIcon>
              <ListItemText
                primary={<Typography>Inactive numbers</Typography>}
              />
            </ListItem>
          </Link>
        </List>
        <Divider />
        <List>
          <Link to={prefix + "/about/"} onClick={this.toggleNav}>
            <ListItem button>
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText primary={<Typography>Help</Typography>} />
            </ListItem>
          </Link>
          <Link to={prefix + "/faq/"} onClick={this.toggleNav}>
            <ListItem button>
              <ListItemIcon>
                <HelpIcon />
              </ListItemIcon>
              <ListItemText primary={<Typography>FAQ</Typography>} />
            </ListItem>
          </Link>
        </List>
      </div>
    );
    return (
      <div className={classes.root}>
        <AdBlockDetectedWrapper>
          <Dialog open={true} keepMounted>
            <DialogTitle style={{ display: "flex", alignItems: "center" }}>
              {"AdBlock detected!"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <p>
                  It seems you are using AdBlock extensions.
                  <br />
                  We rely only on Ads for providing eternally-free service
                  without user charges.
                  <br />
                  Please add our site to your whitelist and support us:)
                </p>
                <p>
                  AdBlock拡張機能が有効です。
                  <br />
                  私達のサービスは広告収入によって維持されており、広告によってユーザーの皆様から利用料金を徴収しない体制を実現しています。
                  <br />
                  AdBlockを無効化して、私達のサービスを支援し、私たちのサービスをご活用いただきますようお願いします。
                </p>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </AdBlockDetectedWrapper>
        <HideOnScroll {...this.props}>
          <AppBar
            className={
              window.location.href.split("/").pop() === ""
                ? "appBarIndex " + classes.appBar
                : classes.appBar
            }
          >
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                className={classes.menuButton}
                onClick={this.toggleNav}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" component="h1" className={classes.title}>
                ReceiveSMSForFree
              </Typography>
            </Toolbar>
          </AppBar>
        </HideOnScroll>
        <nav className={classes.drawer}>
          <Hidden implementation="css">
            <Drawer open={isOpen} onClose={this.toggleNav}>
              {drawer(false)}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>{this.props.children}</main>
      </div>
    );
  }
}

export default withRouter(
  withStyles(styles, { withTheme: true })(GlobalHeader)
);
