import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { RefLink } from "../../common/anchor";
import Link from "@material-ui/core/Link";
import AboutBox from "../common/about";
import { fetcher } from "../../common/fetch";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Footer from "../common/footer";
import Buttons from "../common/buttons";
import { readytoreceive } from "../common/texts";
import AdsCard from "../../common/ad";

export default class ServiceIndex extends React.Component<
  {
    inactive: boolean;
  },
  {
    services: any[];
    loading: boolean;
  }
> {
  constructor(props: { inactive: boolean }) {
    super(props);
    this.state = {
      services: [],
      loading: true,
    };
  }

  async componentDidMount() {
    try {
      const res = await fetcher("getPhoneByServices", {
        active: 1,
      });
      const r = await res.json();
      if (!r.error) {
        this.setState({
          services: r.body,
          loading: false,
        });
      } else {
        throw new Error("e");
      }
    } catch (e) {
      this.setState({
        services: [],
        loading: false,
      });
    }
  }

  render() {
    const { services, loading } = this.state;
    window.document.title =
      "Filter by Countries - ReceiveSMSForFree | Free valid SMS Numbers" +
      readytoreceive;
    return (
      <Container>
        <Typography variant="h3" component="h1" align="center">
          Receive SMS Worldwide
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom align="center">
          Free valid SMS Numbers {readytoreceive}
        </Typography>
        <Buttons />
        <AdsCard />
        <Typography variant="h6" component="h6" style={{ textAlign: "center" }}>
          Filter by Services
        </Typography>
        {!loading && (
          <Grid container style={{ margin: "20px 0" }} justify="space-between">
            {services.map((item: any) => {
              return (
                <Grid item xs={12} sm={6} md={4} style={{ margin: "5px 0" }}>
                  <Card
                    key={item.country}
                    style={{
                      width: "100%",
                      background: "transparent",
                      boxShadow: "0 0 0 0",
                    }}
                  >
                    <CardContent>
                      <Grid container alignItems={"center"}>
                        <Grid item xs={9}>
                          <Typography color="textSecondary" gutterBottom>
                            {item.message_from}
                          </Typography>
                          <Typography variant="h5" component="h2">
                            <Link
                              component={RefLink}
                              to={"/service/" + item.message_from}
                            >
                              {item.SUM} messages received
                            </Link>
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )}
        {loading && (
          <div>
            <CircularProgress />
          </div>
        )}
        <Grid container>
          <Grid
            xs={12}
            sm={12}
            md={6}
            item
            style={{ margin: "10px 0", padding: "10px" }}
          >
            <Typography variant="h5" component="h3" gutterBottom>
              Why you need to filter phone numbers by countries
            </Typography>
            <p>
              Sometimes there are some services that accepts only phone numbers
              within a specific country.
              <br />
              We are working to have phone numbers in as many countries as
              possible.
              <br />
              If phone numbers in the country you need are not on the list, you
              can request us for free.
            </p>
            <p>
              Please contact (sms-online-contact@protonmail.com) and tell us
              which nation you need.
            </p>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={6}
            item
            style={{ margin: "10px 0", padding: "10px" }}
          >
            <Typography variant="h5" component="h3" gutterBottom>
              Having problems?
            </Typography>
            <p>
              If you have problems on our services, please feel free to contact
              us(sms-online-contact@protonmail.com).
            </p>
            <p>
              Or, please refer to the{" "}
              <Link component={RefLink} to={"/faq/"}>
                Frequently asked questions
              </Link>{" "}
              page.
            </p>
          </Grid>
        </Grid>
        <AboutBox />
        <Footer />
      </Container>
    );
  }
}
