import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import HomeIcon from "@material-ui/icons/Home";
import { RefLink } from "../../common/anchor";
import Link from "@material-ui/core/Link";
import Footer from "../common/footer";

export default class ContactZh extends React.Component<{}, {}> {
  render() {
    window.document.title = "联系我们 - ReceiveSMSForFree | 免费使用短信服务";
    return (
      <Container>
        <Link
          component={RefLink}
          to={"/ja/"}
          style={{ marginBottom: "10px", display: "block" }}
        >
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            startIcon={<HomeIcon />}
          >
            Back to Top
          </Button>
        </Link>
        <Typography variant="h3" component="h1" align="center">
          ReceiveSMS
          <br />
          ForFree
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom align="center">
          联系我们
        </Typography>
        <p>
          我们仅通过电子邮件回复查询。
          <br />
          如果您有任何问题，请联系sms-online-contact@protonmail.com。
        </p>
        <p>
          最多可能需要两天才能回复您的查询。
          <br />
          我们只能处理英文，中文和日文信息。
        </p>
        <Typography variant="h5" component="h3" gutterBottom>
          To investigators
        </Typography>
        <p>
          If you have any questions related to the illegal production and use of
          an electromagnetic record, please contact us at the above e-mail
          address.
        </p>
        <Footer />
      </Container>
    );
  }
}
