import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import PhoneCard from "../common/list";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { RefLink } from "../../common/anchor";
import Link from "@material-ui/core/Link";
import AboutBox from "../common/about";
import { fetcher } from "../../common/fetch";
import { RouteComponentProps } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { countryCode, countryCodeToJa } from "../../../scripts/country";
import Footer from "../common/footer";
import Buttons from "../common/buttons";
import AdsCard from "../../common/ad";

export default class EachCountryJa extends React.Component<
  RouteComponentProps,
  {
    phones: any[];
    countries: any[];
    loading: boolean;
  }
> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      phones: [],
      countries: [],
      loading: true,
    };
  }

  async componentDidUpdate(prevProps: any) {
    if (prevProps.match.params.code !== (this.props.match.params as any).code) {
      this.setState({ phones: [], countries: [], loading: true });
      await this.componentDidMount();
    }
  }

  async componentDidMount() {
    try {
      const res = await fetcher("getPhoneListByCountry", {
        country: ((this.props.match.params as any).code || "").replace(
          /%20/g,
          " "
        ),
      });
      const country = await fetcher("getPhoneByCountry", {
        active: 1,
      });
      const r = await res.json();
      const countryResult = await country.json();
      if (!r.error) {
        this.setState({
          phones: r.body,
          countries: countryResult.body,
          loading: false,
        });
      } else {
        throw new Error("e");
      }
    } catch (e) {
      console.log(e);
      this.setState({
        phones: [],
        loading: false,
      });
    }
  }

  render() {
    window.document.title =
      countryCodeToJa(
        ((this.props.match.params as any).code || "").replace(/%20/g, " ")
      ) +
      "の使い捨て電話番号 - ReceiveSMSForFree | 無料で使える使い捨てSMSサービス";
    const { phones, loading, countries } = this.state;
    return (
      <Container>
        <Typography variant="h3" component="h1" align="center">
          {countryCodeToJa(
            ((this.props.match.params as any).code || "").replace(/%20/g, " ")
          )}
          の使い捨て電話番号
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom align="center">
          SMSを受け取る準備は整いましたか？さあ、はじめましょう！
        </Typography>
        <Buttons />
        <AdsCard />
        <Typography variant="h6" component="h6" style={{ textAlign: "center" }}>
          すべての利用可能な番号を表示
        </Typography>
        {!loading && (
          <Grid container style={{ margin: "20px 0" }}>
            {phones.map((item, i) => {
              return (
                <Grid item xs={12} lg={6} md={6} key={i}>
                  <PhoneCard
                    number={item.number}
                    country={item.country}
                    added={item.added}
                    votes={item.votes}
                    upvote={item.upvote}
                    counter={item.counter}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
        {!loading && phones.length === 0 && (
          <p style={{ textAlign: "center" }}>
            現在使用可能な番号はありません。
          </p>
        )}
        {loading && (
          <div>
            <CircularProgress />
          </div>
        )}
        <AdsCard />
        <Typography variant="h6" component="h6" style={{ textAlign: "center" }}>
          他の国の電話番号
        </Typography>
        {!loading && (
          <Grid container style={{ margin: "20px 0" }} justify="space-between">
            {countries.map((item: any) => {
              return (
                <Grid item xs={12} sm={6} md={4}>
                  <Card
                    key={item.country}
                    style={{
                      width: "100%",
                      background: "transparent",
                      boxShadow: "0 0 0 0",
                    }}
                  >
                    <CardContent>
                      <Grid container alignItems={"center"}>
                        <Grid item xs={3}>
                          <span
                            style={{ fontSize: "2rem" }}
                            className={
                              "flag-icon flag-icon-" + countryCode(item.country)
                            }
                          ></span>
                        </Grid>
                        <Grid item xs={9}>
                          <Typography color="textSecondary" gutterBottom>
                            {item.country}
                          </Typography>
                          <Typography variant="h5" component="h2">
                            <Link
                              component={RefLink}
                              to={"/ja/country/" + item.country}
                            >
                              {item.SUM} 個の番号が使用可能
                            </Link>
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )}
        <Grid container style={{ padding: "10px", margin: "10px 0" }}>
          <Grid item xs={12} lg={6}>
            <Typography variant="h5" component="h3" gutterBottom>
              電話番号を追加で購入したくないですか？
            </Typography>
            <p>
              そんなあなたには私達のサービスがぴったりです！
              <br />
              私達のサービスは100%無料で、追加料金は一切かかりません。
            </p>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography variant="h5" component="h3" gutterBottom>
              個人情報を保護しましょう
            </Typography>
            <p>
              インターネットに情報を晒すことに抵抗がありますか。
              <br />
              それならば、私達のサービスを使って下さい。私達の電話番号を、あなたの本物の電話番号を登録する身代わりに使うことができます。
              <br />
              昨今では、情報化社会の進展により、あなたの気づかない間に個人情報を売買されるケースが増加しています。
              <br />
              私達は一切あなたの個人情報を収集しません。
            </p>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography variant="h5" component="h3" gutterBottom>
              SMS認証がわずらわしいですか？
            </Typography>
            <p>
              私達のサービスはTwitter、Google、Facebook、Instagram、WeChat、Alipay、メルカリやその他のSMS認証を要するサービスに対応しています。
              <br />
              私達は毎週電話番号を追加するべく努力しているため、安定してサービスをお使いいただけるはずです。
            </p>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography variant="h5" component="h3" gutterBottom>
              高速かつ安定
            </Typography>
            <p>
              私たちのサービスは99.9%の可用性を誇ります。
              <br />
              すべてのSMS配送サービスは自動化されており、5分以内にあなたのもとへSMSをお届けします。
            </p>
          </Grid>
        </Grid>
        <AboutBox />
        <Footer />
      </Container>
    );
  }
}
