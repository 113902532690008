import React from "react";
import Link from "@material-ui/core/Link";
import { RefLink } from "../../common/anchor";

export default class Footer extends React.Component<{}, {}> {
  render() {
    return (
      <div style={{ textAlign: "center" }}>
        <p>(c) 2022- ReceiveSMSForFree</p>
        <p>
          FREE SMS NUMBERS, SMS AUTHENTICATION BYPASS, SMS VERIFICATION BYPASS
        </p>
        <p
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Link component={RefLink} to={"/ja/country/United States"}>
            United States
          </Link>
          <Link component={RefLink} to={"/ja/country/China"}>
            Republic of China
          </Link>
          <Link component={RefLink} to={"/ja/country/United Kingdom"}>
            United Kingdom
          </Link>
          <Link component={RefLink} to={"/ja/country/Japan"}>
            Japan
          </Link>
          <Link component={RefLink} to={"/ja/country/South Korea"}>
            Korea
          </Link>
          <Link component={RefLink} to={"/ja/country/India"}>
            India
          </Link>
          <Link component={RefLink} to={"/ja/country/Russia"}>
            Russia
          </Link>
          <Link component={RefLink} to={"/ja/country/Canada"}>
            Canada
          </Link>
          <Link component={RefLink} to={"/ja/country/Taiwan"}>
            Taiwan
          </Link>
          <Link component={RefLink} to={"/ja/country/Hong Kong"}>
            Hong Kong
          </Link>
          <Link component={RefLink} to={"/ja/country/Brazil"}>
            Brazil
          </Link>
        </p>
        <p
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
          <Link component={RefLink} to={"/ja/about/"}>
            About
          </Link>
          <Link component={RefLink} to={"/ja/faq/"}>
            FAQ
          </Link>
          <Link component={RefLink} to={"/ja/contact/"}>
            Contact
          </Link>
          <Link component={RefLink} to={"/ja/inactive_numbers/"}>
            Archived Numbers
          </Link>
        </p>
        <p
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
          <Link component={RefLink} to={"/"}>
            English
          </Link>
          <Link component={RefLink} to={"/ja/"}>
            日本語
          </Link>
          <Link component={RefLink} to={"/zh/"}>
            簡体字
          </Link>
        </p>
        <p
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
          <Link component={RefLink} to={"/terms/"}>
            Terms of Use(EN)
          </Link>
          <Link component={RefLink} to={"/policy/"}>
            Privacy Policy(EN)
          </Link>
        </p>
        <small>
          カードローン　証券口座　クレジットカード　借金　リボ払い　銀行口座　株　FX　仮想通貨　暗号通貨
          <br />
          即金　ショッピング枠現金化　銀行ローン　低金利　分割払い　信用取引　クレジットローン　カード審査
        </small>
      </div>
    );
  }
}
