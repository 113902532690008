import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import PhoneCard from "../common/list";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import HotelIcon from "@material-ui/icons/Hotel";
import { RefLink } from "../../common/anchor";
import Link from "@material-ui/core/Link";
import AboutBox from "../common/about";
import { fetcher } from "../../common/fetch";
import Footer from "../common/footer";
import Buttons from "../common/buttons";
import ColumnList from "../column/list";
import AdsCard from "../../common/ad";

export default class IndexJa extends React.Component<
  {
    inactive: boolean;
  },
  {
    phones: any[];
    loading: boolean;
  }
> {
  constructor(props: { inactive: boolean }) {
    super(props);
    this.state = {
      phones: [],
      loading: true,
    };
  }

  async componentDidMount() {
    try {
      const res = await fetcher("getPhoneList", {
        active: 1,
      });
      const r = await res.json();
      if (!r.error) {
        this.setState({
          phones: r.body,
          loading: false,
        });
      } else {
        throw new Error("e");
      }
    } catch (e) {
      this.setState({
        phones: [],
        loading: false,
      });
    }
  }

  render() {
    window.document.title =
      "ReceiveSMSForFree | 無料で使える使い捨てSMSサービス";
    const { phones, loading } = this.state;
    return (
      <Container>
        <Typography variant="h3" component="h1" align="center">
          ReceiveSMS
          <br />
          ForFree
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom align="center">
          今すぐ使う無料の使い捨てSMS
        </Typography>
        <AdsCard />
        <Buttons />
        <Typography variant="h6" component="h6" style={{ textAlign: "center" }}>
          最近追加された番号
        </Typography>
        {!loading && (
          <Grid container style={{ margin: "20px 0" }}>
            {phones.map((item, i) => {
              return (
                <Grid item xs={12} lg={6} md={6} key={i}>
                  <PhoneCard
                    number={item.number}
                    country={item.country}
                    added={item.added}
                    votes={item.votes}
                    upvote={item.upvote}
                    counter={item.counter}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
        {loading && (
          <div>
            <CircularProgress />
          </div>
        )}
        <Link component={RefLink} to={"/ja/inactive_numbers/"}>
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            startIcon={<HotelIcon />}
          >
            廃止された電話番号
          </Button>
        </Link>
        <Grid container style={{ padding: "10px", margin: "10px 0" }}>
          <Grid item xs={12} lg={6}>
            <Typography variant="h5" component="h3" gutterBottom>
              電話番号を追加で購入したくないですか？
            </Typography>
            <p>
              そんなあなたには私達のサービスがぴったりです！
              <br />
              私達のサービスは100%無料で、追加料金は一切かかりません。
            </p>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography variant="h5" component="h3" gutterBottom>
              個人情報を保護しましょう
            </Typography>
            <p>
              インターネットに情報を晒すことに抵抗がありますか。
              <br />
              それならば、私達のサービスを使って下さい。私達の電話番号を、あなたの本物の電話番号を登録する身代わりに使うことができます。
              <br />
              昨今では、情報化社会の進展により、あなたの気づかない間に個人情報を売買されるケースが増加しています。
              <br />
              私達は一切あなたの個人情報を収集しません。
            </p>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography variant="h5" component="h3" gutterBottom>
              SMS認証がわずらわしいですか？
            </Typography>
            <p>
              私達のサービスはTwitter、Google、Facebook、Instagram、WeChat、Alipay、メルカリやその他のSMS認証を要するサービスに対応しています。
              <br />
              私達は毎週電話番号を追加するべく努力しているため、安定してサービスをお使いいただけるはずです。
            </p>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography variant="h5" component="h3" gutterBottom>
              高速かつ安定
            </Typography>
            <p>
              私たちのサービスは99.9%の可用性を誇ります。
              <br />
              すべてのSMS配送サービスは自動化されており、5分以内にあなたのもとへSMSをお届けします。
            </p>
          </Grid>
        </Grid>
        <ColumnList />
        <AboutBox />
        <Footer />
      </Container>
    );
  }
}
