import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import HomeIcon from "@material-ui/icons/Home";
import { RefLink } from "../../common/anchor";
import Link from "@material-ui/core/Link";
import AboutBox from "../common/about";
import Footer from "../common/footer";
import { readytoreceive } from "../common/texts";

export default class AboutJa extends React.Component<{}, {}> {
  render() {
    window.document.title =
      "About ReceiveSMSForFree | Free valid SMS Numbers" + readytoreceive;
    return (
      <Container>
        <Link
          component={RefLink}
          to={"/ja/"}
          style={{ marginBottom: "10px", display: "block" }}
        >
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            startIcon={<HomeIcon />}
          >
            Back to Top
          </Button>
        </Link>
        <Typography variant="h3" component="h1" align="center">
          ReceiveSMS
          <br />
          ForFree
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom align="center">
          このサイトについて
        </Typography>
        <div style={{ padding: "10px", margin: "10px 0" }}>
          <Typography variant="h5" component="h3" gutterBottom>
            ReceiveSMSForFreeとは？
          </Typography>
          <p>
            ReceiveSMSForFreeは、SMSをオンライン上で受信することができる、最も優れた無料サービスです。
            <br />
            私達は、10ヶ国以上の国家でSMS番号を取得しており、なおもその規模を拡大し続けています。
          </p>
          <p>
            ReceiveSMSForFreeをお使いいただくことで、あなたの個人情報を奪い取ろうとするあらゆる企業やスパムから身を守ることができます。
            <br />
            私達は、完全無料かつ使い捨てができるSMS番号を皆様に提供し、それによってTwitter、Google、Facebook、Instagramなどのサービスと連携することが可能です。
          </p>
        </div>
        <div style={{ padding: "10px", margin: "10px 0" }}>
          <Typography variant="h5" component="h3" gutterBottom>
            このサービスは無料ですか。
          </Typography>
          <p style={{ color: "#ff0000" }}>
            <strong>完全に無料です。</strong>
          </p>
          <p>
            このサービスは学術目的により運営されており、いかなる課金制度も用意されていません。
          </p>
          <p>
            私達は広告収入によってサービスを維持運営しており、その収入はすべて新しい電話番号を取得するための原資となります。
          </p>
        </div>
        <AboutBox />
        <div style={{ padding: "10px", margin: "10px 0" }}>
          <Typography variant="h5" component="h3" gutterBottom>
            私達が活用する技術
          </Typography>
          <p>
            私達が本サイトを開設するにあたって、Golang、Java、C++、JavaScriptなどの言語を用いています。
          </p>
          <p>
            私達が管理する電話番号に届けられるあらゆるメッセージを効率よく受信管理するため、仮想電話技術を活用しています。
            <br />
            この技術により、配信されるSMSは1つの中央集権サーバーに集約され、それらが統一フォーマットに整形された後にSQLサーバーに保管されます。
          </p>
          <p>このシステムには3つの利点があります:</p>
          <ul>
            <li>新たな電話番号を追加することが限りなく容易である。</li>
            <li>全ての手順が自動化されている。</li>
            <li>私たちのサービスを世界中で安定的に使っていただける。</li>
          </ul>
          <p>
            このサービスは文字通り「一石二鳥」です--なぜならば、私達は研究を前進させることができ、ユーザーは電話番号の制約から開放され、自由を手に入れるからです。
          </p>
        </div>
        <div style={{ padding: "10px", margin: "10px 0" }}>
          <Typography variant="h5" component="h3" gutterBottom>
            Our goal
          </Typography>
          <p>
            1. We are researching entirely new personal authentication
            infrastructures that replace traditional SMS authentication.
            <br />
            With this project, we are looking for the vulnerability of
            traditional SMS authentication and forming the basis of a new
            personal authentication systems.
          </p>
          <p>
            2. We are conducting researches to improve the comprehensiveness of
            mobile networks.
            <br />
            By making everyone use our services, we are looking for clues to
            improve the network availability and load tolerance of mobile
            networks.
          </p>
        </div>
        <Footer />
      </Container>
    );
  }
}
