import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import PhoneCard from "../common/list";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { RefLink } from "../../common/anchor";
import Link from "@material-ui/core/Link";
import AboutBox from "../common/about";
import { fetcher } from "../../common/fetch";
import { RouteComponentProps } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { countryCode } from "../../../scripts/country";
import Footer from "../common/footer";
import Buttons from "../common/buttons";
import { readytoreceive } from "../common/texts";
import AdsCard from "../../common/ad";

export default class EachCountry extends React.Component<
  RouteComponentProps,
  {
    phones: any[];
    countries: any[];
    loading: boolean;
  }
> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      phones: [],
      countries: [],
      loading: true,
    };
  }

  async componentDidUpdate(prevProps: any) {
    if (prevProps.match.params.code !== (this.props.match.params as any).code) {
      this.setState({ phones: [], countries: [], loading: true });
      await this.componentDidMount();
    }
  }

  async componentDidMount() {
    try {
      const res = await fetcher("getPhoneListByCountry", {
        country: ((this.props.match.params as any).code || "").replace(
          /%20/g,
          " "
        ),
      });
      const country = await fetcher("getPhoneByCountry", {
        active: 1,
      });
      const r = await res.json();
      const countryResult = await country.json();
      if (!r.error) {
        this.setState({
          phones: r.body,
          countries: countryResult.body,
          loading: false,
        });
      } else {
        throw new Error("e");
      }
    } catch (e) {
      console.log(e);
      this.setState({
        phones: [],
        loading: false,
      });
    }
  }

  render() {
    const { phones, loading, countries } = this.state;
    window.document.title =
      ((this.props.match.params as any).code || "").replace(/%20/g, " ") +
      " FREE SMS Number - ReceiveSMSForFree | Free valid SMS Numbers" +
      readytoreceive;
    return (
      <Container>
        <Typography variant="h3" component="h1" align="center">
          {((this.props.match.params as any).code || "").replace(/%20/g, " ")}{" "}
          FREE SMS Numbers
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom align="center">
          Free valid SMS Numbers {readytoreceive}
        </Typography>
        <Buttons />
        <AdsCard />
        <Typography variant="h6" component="h6" style={{ textAlign: "center" }}>
          ALL AVAILABLE NUMBERS
        </Typography>
        {!loading && (
          <Grid container style={{ margin: "20px 0" }}>
            {phones.map((item, i) => {
              return (
                <Grid item xs={12} lg={6} md={6} key={i}>
                  <PhoneCard
                    number={item.number}
                    country={item.country}
                    added={item.added}
                    votes={item.votes}
                    upvote={item.upvote}
                    counter={item.counter}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
        {!loading && phones.length === 0 && (
          <p style={{ textAlign: "center" }}>
            No numbers are currently available.
          </p>
        )}
        {loading && (
          <div>
            <CircularProgress />
          </div>
        )}
        <AdsCard />
        <Typography variant="h6" component="h6" style={{ textAlign: "center" }}>
          OTHER COUNTRIES
        </Typography>
        {!loading && (
          <Grid container style={{ margin: "20px 0" }} justify="space-between">
            {countries.map((item: any) => {
              return (
                <Grid item xs={12} sm={6} md={4}>
                  <Card
                    key={item.country}
                    style={{
                      width: "100%",
                      background: "transparent",
                      boxShadow: "0 0 0 0",
                    }}
                  >
                    <CardContent>
                      <Grid container alignItems={"center"}>
                        <Grid item xs={3}>
                          <span
                            style={{ fontSize: "2rem" }}
                            className={
                              "flag-icon flag-icon-" + countryCode(item.country)
                            }
                          ></span>
                        </Grid>
                        <Grid item xs={9}>
                          <Typography color="textSecondary" gutterBottom>
                            {item.country}
                          </Typography>
                          <Typography variant="h5" component="h2">
                            <Link
                              component={RefLink}
                              to={"/country/" + item.country}
                            >
                              {item.SUM} Numbers available
                            </Link>
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )}
        <Grid container style={{ padding: "10px", margin: "10px 0" }}>
          <Grid item xs={12} lg={6}>
            <Typography variant="h5" component="h3" gutterBottom>
              Not want to buy any phone numbers?
            </Typography>
            <p>
              Okay, just use our phone numbers!
              <br />
              Our services are 100% free of charge.
            </p>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography variant="h5" component="h3" gutterBottom>
              Keep your privacy SAFE
            </Typography>
            <p>
              You don't want to expose your own phone number on the Internet?
              <br />
              Then, just use our web service. You can use phone numbers instead
              of your actual number.
              <br />
              Nowadays, it becomes more and more informational society and your
              information is being sold without knowing.
              <br />
              We do not ask for any your personal data.
            </p>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography variant="h5" component="h3" gutterBottom>
              Avoid Annoying SMS Verifications
            </Typography>
            <p>
              Our services have performances of bypassing SMS authentications
              such as Twitter,Google,Facebook,Instagram,WeChat,AliPay and etc.
              <br />
              We are adding new phone numbers on weekly basis so you can use our
              service stably.
            </p>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography variant="h5" component="h3" gutterBottom>
              FAST and STABLE
            </Typography>
            <p>
              Our services have 99.9% availability.
              <br />
              All systems for delivering your messages are completely
              automatized so you can see your messages in 5 minutes.
            </p>
          </Grid>
        </Grid>
        <AboutBox />
        <Footer />
      </Container>
    );
  }
}
