import React from "react";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import HomeIcon from "@material-ui/icons/Home";
import { RefLink } from "../../common/anchor";
import Link from "@material-ui/core/Link";
import Footer from "../common/footer";
import { readytoreceive } from "../common/texts";

export default class FAQ extends React.Component<{}, {}> {
  render() {
    window.document.title =
      "Frequently Asked Questions - ReceiveSMSForFree | Free valid SMS Numbers" +
      readytoreceive;
    return (
      <Container>
        <Link
          component={RefLink}
          to={"/"}
          style={{ marginBottom: "10px", display: "block" }}
        >
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            startIcon={<HomeIcon />}
          >
            Back to Top
          </Button>
        </Link>
        <Typography variant="h3" component="h1" align="center">
          ReceiveSMS
          <br />
          ForFree
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom align="center">
          FAQ
        </Typography>
        <Paper style={{ padding: "10px", margin: "10px 0" }}>
          <Typography variant="h5" component="h3" gutterBottom>
            Is this site FREE?
          </Typography>
          <p style={{ color: "#ff0000" }}>
            <strong>Yes and yes, this site is eternally free of charge.</strong>
          </p>
          <p>
            Since this services is maintained for academic purposes, there is no
            systems to receive charges from you.
            <br />
            We are keeping this site available only by advertising income and
            all advertising revenue will be used for adding new phone numbers.
          </p>
          <Typography variant="h5" component="h3" gutterBottom>
            Do you filter text messages?
          </Typography>
          <p>
            Yes, we are filtering messages that we receive to get rid of any
            harmful information like
            unlawful,harmful,treating,abusive,excessively violent,hateful.
          </p>
          <Typography variant="h5" component="h3" gutterBottom>
            I have waited but no messages arrived.
          </Typography>
          <p>
            We are working to able to see the messages we receive on our website
            as quickly as possible, but there are some technical issues, so
            please wait up to 5 minutes.
          </p>
          <p>
            If you don't get any messages you awaits, there are some reasons
            like:
          </p>
          <ul>
            <li>The service banned the phone number.</li>
            <li>There are some issues on our telephones such as off-line.</li>
          </ul>
          <p>
            Please resend short-mail or use other phone numbers available on our
            website.
          </p>
          <Typography variant="h5" component="h3" gutterBottom>
            Is there any ways to support you?
          </Typography>
          <p>Yes, please just share this site to your friends:)</p>
          <Typography variant="h5" component="h3" gutterBottom>
            How often you add new phone numbers?
          </Typography>
          <p>We are working to add phone numbers constantly on weekly basis.</p>
        </Paper>
        <Footer />
      </Container>
    );
  }
}
