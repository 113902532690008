import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Link, Button } from "@material-ui/core";
import { RefLink } from "../../common/anchor";
import React from "react";
import PublicIcon from '@material-ui/icons/Public';
import LanguageIcon from '@material-ui/icons/Language';


export default class Buttons extends React.Component<{},{}>{

  render(){
    return (
      <div>
      <Typography variant="h6" component="h6" style={{textAlign:"center"}}>Search numbers by</Typography>
      <Grid container justify="space-between">
        <Grid item xs={12} md={5}>
          <Link component={RefLink} to={"/phone_number_by_countries/"}>
            <Button fullWidth color="primary" variant="outlined" style={{marginBottom:"5px"}} startIcon={<PublicIcon/>}>Countries</Button>
          </Link>
        </Grid>
        <Grid item xs={12} md={5}>
          <Link component={RefLink} to={"/phone_number_by_services/"}>
            <Button fullWidth color="primary" variant="outlined" style={{marginBottom:"5px"}} startIcon={<LanguageIcon/>}>Services confirmed</Button>
          </Link>
        </Grid>
      </Grid>
      </div>
    );
  }
}
