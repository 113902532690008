import * as React from 'react';
import AppBar from "../components/header/";
import Index from "../components/pages/index/";
import PhoneDetail from "../components/pages/phoneDetail/";
import {BrowserRouter, Route} from "react-router-dom";
import Inactive from '../components/pages/inactive';
import About from '../components/pages/about';
import FAQ from '../components/pages/faq';
import CountryIndex from '../components/pages/country';
import EachCountry from '../components/pages/eachCountry';
import ScrollToTop from "./scrollToTop";
import ServiceIndex from '../components/pages/services';
import EachService from '../components/pages/eachService';
import IndexJa from '../components/pagesJa/index';
import AboutJa from '../components/pagesJa/about';
import CountryIndexJa from '../components/pagesJa/country';
import ServiceIndexJa from '../components/pagesJa/services';
import EachCountryJa from '../components/pagesJa/eachCountry';
import EachServiceJa from '../components/pagesJa/eachService';
import FAQJa from '../components/pagesJa/faq';
import InactiveJa from '../components/pagesJa/inactive';
import PhoneDetailJa from '../components/pagesJa/phoneDetail';
import IndexZh from '../components/pagesZh/index';
import AboutZh from '../components/pagesZh/about';
import CountryIndexZh from '../components/pagesZh/country';
import ServiceIndexZh from '../components/pagesZh/services';
import EachCountryZh from '../components/pagesZh/eachCountry';
import EachServiceZh from '../components/pagesZh/eachService';
import FAQZh from '../components/pagesZh/faq';
import InactiveZh from '../components/pagesZh/inactive';
import PhoneDetailZh from '../components/pagesZh/phoneDetail';
import ContactJa from '../components/pagesJa/contact';
import Contact from '../components/pages/contact';
import ContactZh from '../components/pagesZh/contact';
import Terms from '../components/pages/terms';
import Policy from '../components/pages/policy';
import ColumnJa from '../components/pagesJa/column';

export default class Router extends React.Component<{},{}> {

  render(){
    return (
      <BrowserRouter>
        <ScrollToTop/>
        <AppBar>
          <Route path="/" exact component={Index}/>
          <Route path="/about/" exact component={About}/>
          <Route path="/phone_number_by_countries/" exact component={CountryIndex}/>
          <Route path="/phone_number_by_services/" exact component={ServiceIndex}/>
          <Route path="/country/:code" exact component={EachCountry}/>
          <Route path="/service/:code" exact component={EachService}/>
          <Route path="/contact/" exact component={Contact}/>
          <Route path="/faq/" exact component={FAQ}/>
          <Route path="/inactive_numbers/" exact component={Inactive}/>
          <Route path="/free_sms_number/:number/:hash" exact component={PhoneDetail}/>
          <Route path="/terms/" exact component={Terms}/>
          <Route path="/policy/" exact component={Policy}/>

          <Route path="/ja/" exact component={IndexJa}/>
          <Route path="/ja/about/" exact component={AboutJa}/>
          <Route path="/ja/phone_number_by_countries/" exact component={CountryIndexJa}/>
          <Route path="/ja/phone_number_by_services/" exact component={ServiceIndexJa}/>
          <Route path="/ja/country/:code" exact component={EachCountryJa}/>
          <Route path="/ja/service/:code" exact component={EachServiceJa}/>
          <Route path="/ja/contact/" exact component={ContactJa}/>
          <Route path="/ja/column/:code" exact component={ColumnJa}/>
          <Route path="/ja/faq/" exact component={FAQJa}/>
          <Route path="/ja/inactive_numbers/" exact component={InactiveJa}/>
          <Route path="/ja/free_sms_number/:number/:hash" exact component={PhoneDetailJa}/>

          <Route path="/zh/" exact component={IndexZh}/>
          <Route path="/zh/about/" exact component={AboutZh}/>
          <Route path="/zh/phone_number_by_countries/" exact component={CountryIndexZh}/>
          <Route path="/zh/phone_number_by_services/" exact component={ServiceIndexZh}/>
          <Route path="/zh/country/:code" exact component={EachCountryZh}/>
          <Route path="/zh/service/:code" exact component={EachServiceZh}/>
          <Route path="/zh/contact/" exact component={ContactZh}/>
          <Route path="/zh/faq/" exact component={FAQZh}/>
          <Route path="/zh/inactive_numbers/" exact component={InactiveZh}/>
          <Route path="/zh/free_sms_number/:number/:hash" exact component={PhoneDetailZh}/>
        </AppBar>
      </BrowserRouter>
    );
  }

}
