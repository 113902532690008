import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { RouteComponentProps } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { RefLink } from "../../common/anchor";
import Link from "@material-ui/core/Link";
import { fetcher, timeDiff } from "../../common/fetch";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import moment from "moment";
import AboutBox from "../common/about";
import Footer from "../common/footer";
import Buttons from "../common/buttons";
import { readytoreceive } from "../common/texts";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import PhoneCard from "../common/list";
import AdsCard from "../../common/ad";

export default class EachService extends React.Component<
  RouteComponentProps,
  {
    loading: boolean;
    sms: any[];
    recommend: any[];
  }
> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      loading: true,
      sms: [],
      recommend: [],
    };
  }

  async componentDidMount() {
    try {
      const services = await fetcher("getMessagesByService", {
        service: (this.props.match.params as any).code || "",
      });
      const servicesResult = await services.json();
      const recommend = await fetcher("getPhoneListRecommend", {});
      const recommendResult = await recommend.json();
      if (!servicesResult.error) {
        this.setState({
          sms: servicesResult.body,
          recommend: recommendResult.body,
          loading: false,
        });
      } else {
        throw new Error("e");
      }
    } catch (e) {
      this.setState({
        sms: [],
        loading: false,
      });
    }
  }

  render() {
    const { loading, sms, recommend } = this.state;
    const service = (this.props.match.params as any).code || "";
    window.document.title =
      "SMS Received from " +
      service +
      " - ReceiveSMSForFree | Free valid SMS Numbers";
    return (
      <Container>
        <Typography variant="h3" component="h1" align="center">
          SMS received from {service}
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom align="center">
          Free valid SMS Numbers {readytoreceive}
        </Typography>
        <Buttons />
        <AdsCard />
        {loading && (
          <div>
            <CircularProgress />
          </div>
        )}
        {!loading && !sms && <div>Fetch error</div>}
        {sms && (
          <div>
            {sms.map((item: any) => {
              const time = moment.utc(item.date).local();
              return (
                <Card key={item.date} style={{ margin: "10px 0" }}>
                  <CardContent>
                    <Typography color="textSecondary" align="right">
                      {timeDiff(time)}
                    </Typography>
                    <Typography color="textSecondary" gutterBottom>
                      [{item.message_from}]
                    </Typography>
                    <Typography variant="body2" component="p">
                      {item.message_body}
                    </Typography>
                  </CardContent>
                </Card>
              );
            })}
          </div>
        )}
        <p style={{ textAlign: "center", margin: "10px auto" }}>
          We show only latest 15 messages due to security issues.
        </p>
        <Divider style={{ margin: "10px 0" }} />
        <Typography variant="h5" component="h3" style={{ textAlign: "center" }}>
          Recommended for you
        </Typography>
        {!loading && (
          <Grid container style={{ margin: "20px 0" }}>
            {recommend.map((item, i) => {
              return (
                <Grid item xs={12} lg={6} md={6} key={i}>
                  <PhoneCard
                    number={item.number}
                    country={item.country}
                    added={item.added}
                    votes={item.votes}
                    upvote={item.upvote}
                    counter={item.counter}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
        <Typography variant="h5" component="h2" gutterBottom align="center">
          More 100% Free SMS Number is available!
        </Typography>
        <Link component={RefLink} to={"/"}>
          <Button fullWidth variant="outlined" color="primary">
            See more numbers
          </Button>
        </Link>
        <AboutBox />
        <Footer />
      </Container>
    );
  }
}
