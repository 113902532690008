import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { RefLink } from "../../common/anchor";

export default class AboutBox extends React.Component<{}, {}> {
  render() {
    return (
      <div>
        <Typography variant="h5" component="h3" gutterBottom>
          关于我们
        </Typography>
        <Grid container>
          <Grid item xs={12} lg={4}>
            <img
              src="/img/us.webp"
              alt="about us"
              style={{ width: "100%", padding: "20px" }}
            />
          </Grid>
          <Grid xs={12} lg={8}>
            <p>
              我们是由上海交通大学的6名学生组成的团队。
              它由美国，日本，印度和中国成员组成。
              <br />
              我们正在研究移动网络的可扩展性，而此ReceiveSMSForFree是该研究的一部分。
            </p>
            <p>
              我们可能会匿名化通过此服务传达的SMS内容，并将其用作无法识别个人的统计数据，但我们根本不关心SMS的内容。
              <br />
              请放心使用此服务。
            </p>
            <p>
              <Link component={RefLink} to={"/zh/about/"}>
                单击此处以获取有关此服务的更多信息。
              </Link>
            </p>
          </Grid>
        </Grid>
      </div>
    );
  }
}
