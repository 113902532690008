import React from "react";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { RouteComponentProps } from "react-router-dom";
import { percentageColoring } from "../../common/percentage";
import Button from "@material-ui/core/Button";
import { RefLink } from "../../common/anchor";
import Link from "@material-ui/core/Link";
import { fetcher, timeDiff } from "../../common/fetch";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import moment from "moment";
import Fab from "@material-ui/core/Fab";
import CachedIcon from "@material-ui/icons/Cached";
import AboutBox from "../common/about";
import { reverseCountryCode } from "../../../scripts/country";
import Footer from "../common/footer";
import Snackbar from "@material-ui/core/Snackbar";
import PhoneCard from "../common/list";
import AdsCard from "../../common/ad";
import { sbtr } from "../../common/substr";

export default class Index extends React.Component<
  RouteComponentProps,
  {
    loading: boolean;
    result: any;
    sms: any[];
    services: any[];
    recommend: any[];
    voted: boolean;
    voteLoader: boolean;
    snackOpen: boolean;
  }
> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      loading: true,
      result: null,
      sms: [],
      services: [],
      recommend: [],
      voted: false,
      voteLoader: false,
      snackOpen: false,
    };
  }

  async componentDidUpdate(prevProps: any) {
    if (
      prevProps.match.params.number !== (this.props.match.params as any).number
    ) {
      this.setState({ loading: true });
      await this.componentDidMount();
    }
  }

  async componentDidMount() {
    try {
      const res = await fetcher("getPhoneDetail", {
        number: (this.props.match.params as any).number || "",
      });
      const sms = await fetcher("getSMS", {
        number: (this.props.match.params as any).number || "",
      });
      const services = await fetcher("getPhoneNumberByServicesReceived", {
        number: (this.props.match.params as any).number || "",
      });
      const recommend = await fetcher("getPhoneListRecommend", {});
      const r = await res.json();
      const smsresult = await sms.json();
      const servicesResult = await services.json();
      const recommendResult = await recommend.json();
      if (!r.error) {
        this.setState({
          result: r,
          sms: smsresult.body,
          services: servicesResult.body,
          recommend: recommendResult.body,
          loading: false,
        });
      } else {
        throw new Error("e");
      }
    } catch (e) {
      this.setState({
        result: null,
        loading: false,
      });
    }
  }

  reload = async () => {
    window.location.href =
      "/free_sms_number/" +
      ((this.props.match.params as any).number || "") +
      "/" +
      sbtr();
    /*
    if(this.state.loading){return;}
    this.setState({loading:true});
    const sms = await fetcher("getSMS",{
      number:(this.props.match.params as any).number || ""
    });
    const smsresult = await sms.json();
    if(!smsresult.error){
      this.setState({
        sms:smsresult.body,
        loading:false
      })
    }
    */
  };

  vote = async (vote: number) => {
    if (this.state.voteLoader) {
      return;
    }
    this.setState({ voteLoader: true });
    await fetcher("phoneVote", {
      number: (this.props.match.params as any).number || "",
      vote: vote,
    });
    setTimeout(() => {
      this.setState({ voted: true });
    }, 800);
  };

  copy = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(
        (this.props.match.params as any).number || ""
      );
      this.setState({ snackOpen: true });
      setTimeout(this.handleClose, 1000);
    }
  };

  handleClose = () => this.setState({ snackOpen: false });

  render() {
    const {
      loading,
      result,
      voted,
      voteLoader,
      sms,
      services,
      snackOpen,
      recommend,
    } = this.state;
    const number = (this.props.match.params as any).number || "";
    const PNF = require("google-libphonenumber").PhoneNumberFormat;
    const phoneUtil =
      require("google-libphonenumber").PhoneNumberUtil.getInstance();
    const phoneNumber = phoneUtil.parseAndKeepRawInput(number, "US");
    const countryCode = phoneUtil.getRegionCodeForNumber(phoneNumber);
    const vote = result
      ? Math.round(
          (Number(result.body.upvote) / Number(result.body.votes)) * 100
        ) + "%"
      : "0%";
    window.document.title =
      number +
      " - ReceiveSMSForFree | Free valid " +
      reverseCountryCode(countryCode) +
      " SMS Numbers";
    return (
      <Container>
        <Link component={RefLink} to={"/"}>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            style={{ marginBottom: "10px" }}
          >
            Search numbers
          </Button>
        </Link>
        <AdsCard />
        <Typography variant="h4" component="h1" align="center">
          {phoneUtil.format(phoneNumber, PNF.INTERNATIONAL)}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={this.copy}
          style={{ display: "block", margin: "5px auto" }}
        >
          Click here to copy
        </Button>
        <Snackbar
          open={snackOpen}
          onClose={this.handleClose}
          message="Copied!"
        />
        <Typography variant="h6" component="h6" align="center">
          {result && result["body"]["active"] && (
            <span>
              {result["body"]["active"] === "1" ? "ACTIVE | " : "ARCHIVED | "}
            </span>
          )}
          <Link
            component={RefLink}
            to={"/country/" + reverseCountryCode(countryCode)}
          >
            allocated in{" "}
            <span
              className={"flag-icon flag-icon-" + countryCode.toLowerCase()}
            ></span>
            {countryCode}
          </Link>
        </Typography>
        {loading && (
          <div>
            <CircularProgress />
          </div>
        )}
        {!loading && !result && <div>Fetch error</div>}

        <div className="voteContainer">
          <div
            className="voteBar"
            style={{ width: vote, backgroundColor: percentageColoring(vote) }}
          >
            {vote}
          </div>
        </div>
        {!voted && (
          <div>
            <Typography variant="body1" component="p" align="center">
              Was this phone number useful?
            </Typography>
            {voteLoader && (
              <div>
                <CircularProgress />
              </div>
            )}
            {!voteLoader && (
              <Grid container justify="space-around">
                <Grid item xs={3} style={{ textAlign: "center" }}>
                  <Button
                    onClick={() => this.vote(0)}
                    style={{ background: "#a5ffa4", color: "#1c1c1c" }}
                  >
                    <ThumbUpIcon />
                    <span style={{ paddingLeft: "3px" }}>Useful!</span>
                  </Button>
                </Grid>
                <Grid item xs={3} style={{ textAlign: "center" }}>
                  <Button
                    onClick={() => this.vote(1)}
                    style={{ background: "#ff8383", color: "#1c1c1c" }}
                  >
                    <ThumbDownIcon />
                    <span style={{ paddingLeft: "3px" }}>Bad</span>
                  </Button>
                </Grid>
              </Grid>
            )}
          </div>
        )}
        {voted && (
          <p style={{ textAlign: "center" }}>
            Thank you for voting! Your vote will be reflected after a while.
          </p>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "10px 0",
          }}
        >
          <Fab
            aria-label="save"
            color="primary"
            onClick={this.reload}
            disabled={loading}
          >
            <CachedIcon />
          </Fab>
        </div>
        <Typography variant="caption" component="p" align="center">
          Please wait up to 10 minutes.
          <br />
          <Link
            component={RefLink}
            to={"/country/" + reverseCountryCode(countryCode)}
          >
            Click here to see other numbers in {countryCode}.
          </Link>
        </Typography>
        <AdsCard />
        {!loading && result && (
          <div>
            {sms && (
              <div>
                {sms.map((item: any) => {
                  const time = moment.utc(item.date).local();
                  return (
                    <Card key={item.date} style={{ margin: "10px 0" }}>
                      <CardContent>
                        <Typography color="textSecondary" align="right">
                          {timeDiff(time)}
                        </Typography>
                        <Typography color="textSecondary" gutterBottom>
                          [{item.message_from}]
                        </Typography>
                        <Typography variant="body2" component="p">
                          {item.message_body}
                        </Typography>
                      </CardContent>
                    </Card>
                  );
                })}
              </div>
            )}
          </div>
        )}
        <p style={{ textAlign: "center", margin: "10px auto" }}>
          We show only latest 15 messages due to security issues.
        </p>
        <Divider style={{ margin: "10px 0" }} />
        <Typography variant="h5" component="h3" gutterBottom align="center">
          Services confirmed
        </Typography>
        <Typography variant="caption" component="p" align="center">
          This phone number have received messages from these services:
        </Typography>
        <Grid container justify="space-around">
          {services &&
            services.map((item: any) => (
              <Link component={RefLink} to={"/service/" + item.message_from}>
                <Chip
                  color="primary"
                  style={{ margin: "5px" }}
                  label={item.message_from}
                  key={item.message_from}
                />
              </Link>
            ))}
        </Grid>
        <Divider style={{ margin: "10px 0" }} />
        <Typography variant="h5" component="h3" style={{ textAlign: "center" }}>
          RECOMMENDED FOR YOU
        </Typography>
        {!loading && (
          <Grid container style={{ margin: "20px 0" }}>
            {recommend.map((item, i) => {
              return (
                <Grid item xs={12} lg={6} md={6} key={i}>
                  <PhoneCard
                    number={item.number}
                    country={item.country}
                    added={item.added}
                    votes={item.votes}
                    upvote={item.upvote}
                    counter={item.counter}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
        <Divider style={{ margin: "10px 0" }} />
        <Typography variant="h5" component="h2" gutterBottom align="center">
          More 100% Free SMS Number is available!
        </Typography>
        <Link component={RefLink} to={"/"}>
          <Button fullWidth variant="outlined" color="primary">
            See more numbers
          </Button>
        </Link>
        <div style={{ padding: "10px", margin: "10px 0" }}>
          <Typography variant="h5" component="h3" gutterBottom>
            About this number
          </Typography>
          <p>
            We provide our own phone numbers for SMS verification.
            <br />
            You need to accept our terms and privacy policy to use phone numbers
            we provide.
            <br />
            Please be aware that your SMS shortcodes we receive will be exposed
            on this website and therefore there are security risks.
          </p>
        </div>
        <AboutBox />
        <Footer />
      </Container>
    );
  }
}
