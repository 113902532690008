import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import PhoneCard from "../common/list";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { RefLink } from "../../common/anchor";
import Link from "@material-ui/core/Link";
import AboutBox from "../common/about";
import { fetcher } from "../../common/fetch";
import { RouteComponentProps } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { countryCode } from "../../../scripts/country";
import Footer from "../common/footer";
import Buttons from "../common/buttons";
import AdsCard from "../../common/ad";

export default class EachCountryZh extends React.Component<
  RouteComponentProps,
  {
    phones: any[];
    countries: any[];
    loading: boolean;
  }
> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      phones: [],
      countries: [],
      loading: true,
    };
  }

  async componentDidUpdate(prevProps: any) {
    if (prevProps.match.params.code !== (this.props.match.params as any).code) {
      this.setState({ phones: [], countries: [], loading: true });
      await this.componentDidMount();
    }
  }

  async componentDidMount() {
    try {
      const res = await fetcher("getPhoneListByCountry", {
        country: ((this.props.match.params as any).code || "").replace(
          /%20/g,
          " "
        ),
      });
      const country = await fetcher("getPhoneByCountry", {
        active: 1,
      });
      const r = await res.json();
      const countryResult = await country.json();
      if (!r.error) {
        this.setState({
          phones: r.body,
          countries: countryResult.body,
          loading: false,
        });
      } else {
        throw new Error("e");
      }
    } catch (e) {
      console.log(e);
      this.setState({
        phones: [],
        loading: false,
      });
    }
  }

  render() {
    window.document.title =
      ((this.props.match.params as any).code || "").replace(/%20/g, " ") +
      "の電話番号を表示 - ReceiveSMSForFree | 免费使用短信服务";
    const { phones, loading, countries } = this.state;
    return (
      <Container>
        <Typography variant="h3" component="h1" align="center">
          {((this.props.match.params as any).code || "").replace(/%20/g, " ")}
          一次性短信
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom align="center">
          您准备好接收短信了吗？ 让我们开始吧！
        </Typography>
        <Buttons />
        <AdsCard />
        <Typography variant="h6" component="h6" style={{ textAlign: "center" }}>
          查看所有可用号码
        </Typography>
        {!loading && (
          <Grid container style={{ margin: "20px 0" }}>
            {phones.map((item, i) => {
              return (
                <Grid item xs={12} lg={6} md={6} key={i}>
                  <PhoneCard
                    number={item.number}
                    country={item.country}
                    added={item.added}
                    votes={item.votes}
                    upvote={item.upvote}
                    counter={item.counter}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
        {!loading && phones.length === 0 && (
          <p style={{ textAlign: "center" }}>对不起，当前没有可用的数字。</p>
        )}
        {loading && (
          <div>
            <CircularProgress />
          </div>
        )}
        <AdsCard />
        <Typography variant="h6" component="h6" style={{ textAlign: "center" }}>
          其他国家的电话号码
        </Typography>
        {!loading && (
          <Grid container style={{ margin: "20px 0" }} justify="space-between">
            {countries.map((item: any) => {
              return (
                <Grid item xs={12} sm={6} md={4}>
                  <Card
                    key={item.country}
                    style={{
                      width: "100%",
                      background: "transparent",
                      boxShadow: "0 0 0 0",
                    }}
                  >
                    <CardContent>
                      <Grid container alignItems={"center"}>
                        <Grid item xs={3}>
                          <span
                            style={{ fontSize: "2rem" }}
                            className={
                              "flag-icon flag-icon-" + countryCode(item.country)
                            }
                          ></span>
                        </Grid>
                        <Grid item xs={9}>
                          <Typography color="textSecondary" gutterBottom>
                            {item.country}
                          </Typography>
                          <Typography variant="h5" component="h2">
                            <Link
                              component={RefLink}
                              to={"/zh/country/" + item.country}
                            >
                              {item.SUM} 个号
                            </Link>
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )}
        <Grid container style={{ padding: "10px", margin: "10px 0" }}>
          <Grid item xs={12} lg={6}>
            <Typography variant="h5" component="h3" gutterBottom>
              不想购买其他电话号码？
            </Typography>
            <p>
              我们的服务非常适合您！
              <br />
              我们的服务是100％免费的，不收取额外费用。
            </p>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography variant="h5" component="h3" gutterBottom>
              保护个人信息
            </Typography>
            <p>
              你不愿意公开你的信息到互联网？
              <br />
              如果是这样，请使用我们的服务。
              您可以使用我们的电话号码代替注册您的真实电话号码。
              <br />
              如今，随着信息社会的进步，在不通知您的情况下买卖个人信息的情况越来越多。
              <br />
              我们不会收集您的任何个人信息。
            </p>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography variant="h5" component="h3" gutterBottom>
              SMS认证麻烦吗？
            </Typography>
            <p>
              我们的服务支持Twitter，Google，Facebook，Instagram，微信，支付宝，Mercari和其他需要SMS验证的服务。
              <br />
              我们正在努力每周添加电话号码，因此您应该能够稳定地使用该服务。
            </p>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography variant="h5" component="h3" gutterBottom>
              快速稳定
            </Typography>
            <p>
              我们的服务拥有99.9％的可用性。
              <br />
              所有SMS传递服务都是自动化的，并且会在5分钟内向您传递SMS。
            </p>
          </Grid>
        </Grid>
        <AboutBox />
        <Footer />
      </Container>
    );
  }
}
