import React from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { RefLink } from "../../common/anchor";

export default class ColumnList extends React.Component<{}, {}> {
  render() {
    return (
      <div>
        <Typography variant="h5" component="h3" gutterBottom>
          コラム
        </Typography>
        <ul>
          <li>
            <Link component={RefLink} to={"/ja/column/メルカリ/"}>
              【2022年】メルカリの電話番号認証を回避する方法
            </Link>
          </li>
          <li>
            <Link component={RefLink} to={"/ja/column/ラクマ/"}>
              【2022年】ラクマの電話番号認証を回避する方法
            </Link>
          </li>
          <li>
            <Link component={RefLink} to={"/ja/column/Yahoo/"}>
              【2022年】Yahoo!JAPANの電話番号認証を回避する方法
            </Link>
          </li>
          <li>
            <Link component={RefLink} to={"/ja/column/twitter/"}>
              【2022年】Twitterの電話番号認証を回避する方法
            </Link>
          </li>
          <li>
            <Link component={RefLink} to={"/ja/column/facebook/"}>
              【2022年】Facebookの電話番号認証を回避する方法
            </Link>
          </li>
          <li>
            <Link component={RefLink} to={"/ja/column/google/"}>
              【2022年】Googleの電話番号認証を回避する方法
            </Link>
          </li>
          <li>
            <Link component={RefLink} to={"/ja/column/instagram/"}>
              【2022年】Instagramの電話番号認証を回避する方法
            </Link>
          </li>
          <li>
            <Link component={RefLink} to={"/ja/column/line/"}>
              【2022年】LINEの電話番号認証を回避する方法
            </Link>
          </li>
          <li>
            <Link component={RefLink} to={"/ja/column/GameTrade/"}>
              【2022年】GameTradeの電話番号認証を回避する方法
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}
