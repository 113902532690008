import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import HomeIcon from "@material-ui/icons/Home";
import { RefLink } from "../../common/anchor";
import Link from "@material-ui/core/Link";
import AboutBox from "../common/about";
import Footer from "../common/footer";
import { readytoreceive } from "../common/texts";

export default class About extends React.Component<{}, {}> {
  render() {
    window.document.title =
      "About ReceiveSMSForFree | Free valid SMS Numbers" + readytoreceive;
    return (
      <Container>
        <Link
          component={RefLink}
          to={"/"}
          style={{ marginBottom: "10px", display: "block" }}
        >
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            startIcon={<HomeIcon />}
          >
            Back to Top
          </Button>
        </Link>
        <Typography variant="h3" component="h1" align="center">
          ReceiveSMS
          <br />
          ForFree
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom align="center">
          About this site
        </Typography>
        <div style={{ padding: "10px", margin: "10px 0" }}>
          <Typography variant="h5" component="h3" gutterBottom>
            What is ReceiveSMSForFree?
          </Typography>
          <p>
            ReceiveSMSForFree is the best website that offers SMS numbers
            completely free of charge.
            <br />
            We are offering free SMS numbers more than 10 countries.
          </p>
          <p>
            By using ReceiveSMSForFree, you can flee from any companies that
            steal your privacies and spams.
            <br />
            We offer completely free and disposable SMS numbers that can be used
            for SMS verification such as Twitter, Google, Facebook, Instagram
            and etc.
          </p>
        </div>
        <div style={{ padding: "10px", margin: "10px 0" }}>
          <Typography variant="h5" component="h3" gutterBottom>
            Is this site FREE?
          </Typography>
          <p style={{ color: "#ff0000" }}>
            <strong>Yes and yes, this site is eternally free of charge.</strong>
          </p>
          <p>
            Since this services is maintained for academic purposes, there are
            completely no systems to receive charges from you.
            <br />
            We are keeping this site available only by advertising income and
            all advertising revenue will be used for adding new phone numbers.
          </p>
        </div>
        <AboutBox />
        <div style={{ padding: "10px", margin: "10px 0" }}>
          <Typography variant="h5" component="h3" gutterBottom>
            Technologies we use:
          </Typography>
          <p>
            We are using both of modern and old-fashioned programming
            technologies like Golang, Java, C++ and JavaScript.
          </p>
          <p>
            To obtain all messages we receive on phone numbers we manage, we are
            using virtual phone technology.
            <br />A server that collectively manages virtuals phones receives
            all incoming messages and these messages are formatted and added to
            MySQL Server running on another server we manage.
          </p>
          <p>This system has 3 merits for us:</p>
          <ul>
            <li>Extremely easy to add another phone number.</li>
            <li>Completely automatized processes through all procedures.</li>
            <li>Get people all over the world to use our services.</li>
          </ul>
          <p>
            This services is literally "Killing two birds with one stone" -- We
            obtain data we need to progress research further and all users will
            be happy to use free phone numbers!
          </p>
        </div>
        <div style={{ padding: "10px", margin: "10px 0" }}>
          <Typography variant="h5" component="h3" gutterBottom>
            Our goal
          </Typography>
          <p>
            1. We are researching entirely new personal authentication
            infrastructures that replace traditional SMS authentication.
            <br />
            With this project, we are looking for the vulnerability of
            traditional SMS authentication and forming the basis of a new
            personal authentication systems.
          </p>
          <p>
            2. We are conducting researches to improve the comprehensiveness of
            mobile networks.
            <br />
            By making everyone use our services, we are looking for clues to
            improve the network availability and load tolerance of mobile
            networks.
          </p>
        </div>
        <Footer />
      </Container>
    );
  }
}
