import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import HomeIcon from "@material-ui/icons/Home";
import { RefLink } from "../../common/anchor";
import Link from "@material-ui/core/Link";
import Footer from "../common/footer";
import { readytoreceive } from "../common/texts";

export default class Contact extends React.Component<{}, {}> {
  render() {
    window.document.title =
      "Contact - ReceiveSMSForFree | Free valid SMS Numbers" + readytoreceive;
    return (
      <Container>
        <Link
          component={RefLink}
          to={"/ja/"}
          style={{ marginBottom: "10px", display: "block" }}
        >
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            startIcon={<HomeIcon />}
          >
            Back to Top
          </Button>
        </Link>
        <Typography variant="h3" component="h1" align="center">
          ReceiveSMS
          <br />
          ForFree
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom align="center">
          Contact
        </Typography>
        <p>
          We only respond to inquiries by email.
          <br />
          If you have any problems, please contact
          sms-online-contact@protonmail.com.
        </p>
        <p>
          It may take up to two days to reply to your inquiry.
          <br />
          We can only handle English, Chinese and Japanese messages.
        </p>
        <Typography variant="h5" component="h3" gutterBottom>
          To investigators
        </Typography>
        <p>
          If you have any questions related to the illegal production and use of
          an electromagnetic record, please contact us at the above e-mail
          address.
        </p>
        <Footer />
      </Container>
    );
  }
}
