export const countryCode = (input: string) => {
  switch (input) {
    case "United States":
      return "us";
    case "China":
      return "cn";
    case "Russia":
      return "ru";
    case "Japan":
      return "jp";
    case "Taiwan":
      return "tw";
    case "United Kingdom":
      return "gb";
    case "South Korea":
      return "kr";
    case "Canada":
      return "ca";
    case "Hong Kong":
      return "hk";
    case "Brazil":
      return "br";
    case "Singapore":
      return "sg";
    case "Austria":
      return "at";
    case "India":
      return "in";
    case "Australia":
      return "au";
    case "Indonesia":
      return "id";
  }
};

export const reverseCountryCode = (input: string) => {
  if (typeof input !== "string") {
    return "United States";
  }
  switch (input.toLowerCase()) {
    case "us":
      return "United States";
    case "cn":
      return "China";
    case "ru":
      return "Russia";
    case "jp":
      return "Japan";
    case "tw":
      return "Taiwan";
    case "gb":
      return "United Kingdom";
    case "kr":
      return "South Korea";
    case "id":
      return "India";
    case "in":
      return "Canada";
    case "hk":
      return "Hong Kong";
    case "br":
      return "Brazil";
    case "sg":
      return "Singapore";
    case "at":
      return "Austria";
    case "au":
      return "Australia";
    case "id":
      return "Indonesia";
  }
};

export const countryCodeToJa = (input: string) => {
  switch (input) {
    case "United States":
      return "アメリカ合衆国";
    case "China":
      return "中国";
    case "Russia":
      return "ロシア連邦";
    case "Japan":
      return "日本";
    case "Taiwan":
      return "中華民国(台湾)";
    case "United Kingdom":
      return "英国(イギリス)";
    case "South Korea":
      return "韓国";
    case "India":
      return "インド";
    case "Canada":
      return "カナダ";
    case "Hong Kong":
      return "香港";
    case "Brazil":
      return "ブラジル";
    case "Singapore":
      return "シンガポール";
    case "Austria":
      return "オーストリア";
    case "Australia":
      return "オーストラリア";
    case "Indonesia":
      return "インドネシア";
  }
};
