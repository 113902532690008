import React from "react";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import PhoneCard from "../common/list";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { RefLink } from "../../common/anchor";
import Link from "@material-ui/core/Link";
import AboutBox from "../common/about";
import { fetcher } from "../../common/fetch";
import Footer from "../common/footer";

export default class InactiveJa extends React.Component<
  {
    inactive: boolean;
  },
  {
    phones: any[];
    loading: boolean;
  }
> {
  constructor(props: { inactive: boolean }) {
    super(props);
    this.state = {
      phones: [],
      loading: true,
    };
  }

  async componentDidMount() {
    try {
      const res = await fetcher("getPhoneList", {
        active: 0,
      });
      const r = await res.json();
      if (!r.error) {
        this.setState({
          phones: r.body,
          loading: false,
        });
      } else {
        throw new Error("e");
      }
    } catch (e) {
      this.setState({
        phones: [],
        loading: false,
      });
    }
  }

  render() {
    const { phones, loading } = this.state;
    window.document.title =
      "廃止された電話番号 - ReceiveSMSForFree | 無料で使える使い捨てSMSサービス";
    return (
      <Container>
        <Typography variant="h3" component="h1" align="center">
          ReceiveSMS
          <br />
          ForFree
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom align="center">
          無料の使い捨てSMS
        </Typography>
        <Link component={RefLink} to={"/ja/"}>
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            style={{ marginBottom: "15px" }}
          >
            使用可能な電話番号を探す
          </Button>
        </Link>
        <Typography variant="h6" component="h6" style={{ textAlign: "center" }}>
          廃止された電話番号一覧
        </Typography>
        {!loading && (
          <Grid container style={{ margin: "20px 0" }}>
            {phones.map((item, i) => {
              return (
                <Grid item xs={12} lg={6} md={6} key={i}>
                  <PhoneCard
                    number={item.number}
                    country={item.country}
                    added={item.added}
                    votes={item.votes}
                    upvote={item.upvote}
                    counter={item.counter}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
        {loading && (
          <div>
            <CircularProgress />
          </div>
        )}
        <Paper style={{ margin: "10px 0", padding: "15px" }}>
          <Typography variant="h5" component="h3" gutterBottom>
            なぜ「廃止された電話番号」があるのですか？
          </Typography>
          <p>
            私達は、可能な限り多くの方に私たちのサービスを使用してもらうため、多くの電話番号を確保しています。しかし、1つの電話番号あたり1つのサービスに登録できる回数には限りがあり、また、このサイト上で電話番号を公開することでスパムとして判断されてしまう危険性があります。
          </p>
          <p>
            私達は、私達が用意した電話番号が高い確率で使用できているか否かを判断する機構を開発しました。一定回数以上電話番号のチェック機構でエラーが発生した場合、自動で「廃止された電話番号」に移動し、それ以上その電話番号を使用することはできなくなります。
          </p>
          <p>
            電話番号はそれ以上使用できませんが、それまでに受け取ったSMSはこれまで通り確認することができます。
          </p>
        </Paper>
        <Paper style={{ margin: "10px 0", padding: "15px" }}>
          <Typography variant="h5" component="h3" gutterBottom>
            廃止された電話番号を使用している場合
          </Typography>
          <p>
            もし、あなたが廃止された電話番号を使用している場合、現在使用中のサービスから電話番号の連携を解除した上、新たな電話番号をご利用ください。
            <br />
            電話番号はそれ以上利用できませんので、新たにSMSショートコードを受け取ることはできません。
          </p>
          <p>
            私達は、廃止された電話番号や、廃止された電話番号に配信されたショートメールに関するあらゆるお問い合わせにお答えすることができませんので、予めご了承ください。
          </p>
        </Paper>
        <AboutBox />
        <Footer />
      </Container>
    );
  }
}
