import React from "react";
import Link from "@material-ui/core/Link";
import { RefLink } from "../../common/anchor";

export default class Footer extends React.Component<{}, {}> {
  render() {
    return (
      <div style={{ textAlign: "center" }}>
        <p>(c) 2022- ReceiveSMSForFree</p>
        <p>
          FREE SMS NUMBERS, SMS AUTHENTICATION BYPASS, SMS VERIFICATION BYPASS
        </p>
        <p
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Link component={RefLink} to={"/zh/country/United States"}>
            United States
          </Link>
          <Link component={RefLink} to={"/zh/country/China"}>
            Republic of China
          </Link>
          <Link component={RefLink} to={"/zh/country/United Kingdom"}>
            United Kingdom
          </Link>
          <Link component={RefLink} to={"/zh/country/Japan"}>
            Japan
          </Link>
          <Link component={RefLink} to={"/zh/country/South Korea"}>
            Korea
          </Link>
          <Link component={RefLink} to={"/zh/country/India"}>
            India
          </Link>
          <Link component={RefLink} to={"/zh/country/Russia"}>
            Russia
          </Link>
          <Link component={RefLink} to={"/zh/country/Canada"}>
            Canada
          </Link>
          <Link component={RefLink} to={"/zh/country/Taiwan"}>
            Taiwan
          </Link>
          <Link component={RefLink} to={"/zh/country/Hong Kong"}>
            Hong Kong
          </Link>
          <Link component={RefLink} to={"/zh/country/Brazil"}>
            Brazil
          </Link>
        </p>
        <p
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
          <Link component={RefLink} to={"/zh/about/"}>
            About
          </Link>
          <Link component={RefLink} to={"/zh/faq/"}>
            FAQ
          </Link>
          <Link component={RefLink} to={"/zh/contact/"}>
            Contact
          </Link>
          <Link component={RefLink} to={"/zh/inactive_numbers/"}>
            Archived Numbers
          </Link>
        </p>
        <p
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
          <Link component={RefLink} to={"/"}>
            English
          </Link>
          <Link component={RefLink} to={"/ja/"}>
            日本語
          </Link>
          <Link component={RefLink} to={"/zh/"}>
            簡体字
          </Link>
        </p>
        <p
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
          <Link component={RefLink} to={"/terms/"}>
            Terms of Use(EN)
          </Link>
          <Link component={RefLink} to={"/policy/"}>
            Privacy Policy(EN)
          </Link>
        </p>
      </div>
    );
  }
}
