import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import PhoneCard from "../common/list";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import HotelIcon from "@material-ui/icons/Hotel";
import { RefLink } from "../../common/anchor";
import Link from "@material-ui/core/Link";
import AboutBox from "../common/about";
import { fetcher } from "../../common/fetch";
import Footer from "../common/footer";
import Buttons from "../common/buttons";
import AdsCard from "../../common/ad";

export default class IndexZh extends React.Component<
  {
    inactive: boolean;
  },
  {
    phones: any[];
    loading: boolean;
  }
> {
  constructor(props: { inactive: boolean }) {
    super(props);
    this.state = {
      phones: [],
      loading: true,
    };
  }

  async componentDidMount() {
    try {
      const res = await fetcher("getPhoneList", {
        active: 1,
      });
      const r = await res.json();
      if (!r.error) {
        this.setState({
          phones: r.body,
          loading: false,
        });
      } else {
        throw new Error("e");
      }
    } catch (e) {
      this.setState({
        phones: [],
        loading: false,
      });
    }
  }

  render() {
    window.document.title = "ReceiveSMSForFree | 免费使用短信服务";
    const { phones, loading } = this.state;
    return (
      <Container>
        <Typography variant="h3" component="h1" align="center">
          ReceiveSMS
          <br />
          ForFree
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom align="center">
          免费使用短信服务
        </Typography>
        <Buttons />
        <AdsCard />
        <Typography variant="h6" component="h6" style={{ textAlign: "center" }}>
          最近添加的号码
        </Typography>
        {!loading && (
          <Grid container style={{ margin: "20px 0" }}>
            {phones.map((item, i) => {
              return (
                <Grid item xs={12} lg={6} md={6} key={i}>
                  <PhoneCard
                    number={item.number}
                    country={item.country}
                    added={item.added}
                    votes={item.votes}
                    upvote={item.upvote}
                    counter={item.counter}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
        {loading && (
          <div>
            <CircularProgress />
          </div>
        )}
        <Link component={RefLink} to={"/zh/inactive_numbers/"}>
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            startIcon={<HotelIcon />}
          >
            遗弃的电话号码
          </Button>
        </Link>
        <Grid container style={{ padding: "10px", margin: "10px 0" }}>
          <Grid item xs={12} lg={6}>
            <Typography variant="h5" component="h3" gutterBottom>
              不想购买其他电话号码？
            </Typography>
            <p>
              我们的服务非常适合您！
              <br />
              我们的服务是100％免费的，不收取额外费用。
            </p>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography variant="h5" component="h3" gutterBottom>
              保护个人信息
            </Typography>
            <p>
              你不愿意公开你的信息到互联网？
              <br />
              如果是这样，请使用我们的服务。
              您可以使用我们的电话号码代替注册您的真实电话号码。
              <br />
              如今，随着信息社会的进步，在不通知您的情况下买卖个人信息的情况越来越多。
              <br />
              我们不会收集您的任何个人信息。
            </p>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography variant="h5" component="h3" gutterBottom>
              SMS认证麻烦吗？
            </Typography>
            <p>
              我们的服务支持Twitter，Google，Facebook，Instagram，微信，支付宝，Mercari和其他需要SMS验证的服务。
              <br />
              我们正在努力每周添加电话号码，因此您应该能够稳定地使用该服务。
            </p>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography variant="h5" component="h3" gutterBottom>
              快速稳定
            </Typography>
            <p>
              我们的服务拥有99.9％的可用性。
              <br />
              所有SMS传递服务都是自动化的，并且会在5分钟内向您传递SMS。
            </p>
          </Grid>
        </Grid>
        <AboutBox />
        <Footer />
      </Container>
    );
  }
}
