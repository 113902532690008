import React from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import { countryCode } from "../../../scripts/country";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import Link from "@material-ui/core/Link";
import { RefLink } from "../../common/anchor";
import { sbtr } from "../../common/substr";


export default class PhoneCard extends React.Component<{
  number: string,
  country: string,
  votes: string,
  upvote: string,
  added: string,
  counter: string,
},{}>{

  render() {
    const props = this.props;
    return (
      <Card style={{margin:"5px"}}>
        <CardContent>
        <Grid container alignItems={"center"}>
          <Grid item xs={3}>
            <span style={{fontSize:"2rem"}} className={"flag-icon flag-icon-" + countryCode(props.country)}></span>
          </Grid>
          <Grid item xs={9}>
            <Typography color="textSecondary" gutterBottom>
              {props.country}
            </Typography>
            <Typography variant="h5" component="h2">
              <Link component={RefLink} to={"/free_sms_number/" + props.number + "/" + sbtr()}>{props.number}</Link>
            </Typography>
            <Typography color="textSecondary">
              {moment().diff(props.added, "days")} days ago / {Math.round(Number(props.upvote) / Number(props.votes) * 100)}% Upvotes
            </Typography>
            <Typography variant="body2" component="p">
              More than {props.counter || 0} messages received!
            </Typography>
          </Grid>
        </Grid>
        </CardContent>
        <CardActions>
          <Link component={RefLink} to={"/free_sms_number/" + props.number + "/" + sbtr()} style={{width:"100%"}}>
            <Button size="small" fullWidth>Check this number</Button>
          </Link>
        </CardActions>
      </Card>
    );
  }
}
