import React from "react";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import HomeIcon from "@material-ui/icons/Home";
import { RefLink } from "../../common/anchor";
import Link from "@material-ui/core/Link";
import Footer from "../common/footer";

export default class FAQZh extends React.Component<{}, {}> {
  render() {
    window.document.title = "常见问题 - ReceiveSMSForFree | 免费使用短信服务";
    return (
      <Container>
        <Link
          component={RefLink}
          to={"/zh/"}
          style={{ marginBottom: "10px", display: "block" }}
        >
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            startIcon={<HomeIcon />}
          >
            Back to Top
          </Button>
        </Link>
        <Typography variant="h3" component="h1" align="center">
          ReceiveSMS
          <br />
          ForFree
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom align="center">
          常见问题
        </Typography>
        <Paper style={{ padding: "10px", margin: "10px 0" }}>
          <Typography variant="h5" component="h3" gutterBottom>
            这个网站免费吗？
          </Typography>
          <p style={{ color: "#ff0000" }}>
            <strong>是的，这个网站是完全免费的。</strong>
          </p>
          <p>
            它是出于学术目的而运行的，没有任何收取使用费的系统。
            <br />
            该网站由广告收入维护，用于获得新的电话号码。
          </p>
          <Typography variant="h5" component="h3" gutterBottom>
            邮件是否被过滤？
          </Typography>
          <p>
            是。
            为了使许多人舒适地使用它，我们将过滤触犯法律的内容，危险内容，恶作剧，暴力或助长歧视的内容，以及在邮件处理过程中可能成为垃圾邮件的邮件。
          </p>
          <Typography variant="h5" component="h3" gutterBottom>
            我等了一段时间，但没有收到消息。
          </Typography>
          <p>
            我们正在努力使消息尽快在网站上反映出来，但是由于技术合同的原因，交货可能会延迟5-10分钟。
          </p>
          <p>如果等待后仍未收到该消息，则可能是以下原因。</p>
          <ul>
            <li>该电话号码可能已被禁止使用。</li>
            <li>发生了问题，例如电话号码与我们的网络断开连接。</li>
          </ul>
          <p>请重新发送您的SMS消息或使用我们网站上列出的其他电话号码。</p>
          <Typography variant="h5" component="h3" gutterBottom>
            有什么方法可以帮助SMS Online？
          </Typography>
          <p>是的，向您的朋友推荐这个网站:)</p>
          <Typography variant="h5" component="h3" gutterBottom>
            我的电话号码何时添加？
          </Typography>
          <p>只要有可能，我们都会努力每周添加一次新的电话号码。</p>
        </Paper>
        <Footer />
      </Container>
    );
  }
}
