

export const percentageColoring = (percent:string)=>{
  const number = percent.match(/\d+/g);
  if(!number){
    return "#ff0000";
  }
  const num = Number(number[0]);
  if(num > 80){
    return "#4CAF50";
  }
  if(num > 60){
    return "#2196F3";
  }
  if(num > 40){
    return "#ffd400";
  }
  return "#f44336";
}
