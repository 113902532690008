import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { RefLink } from "../../common/anchor";
import Link from "@material-ui/core/Link";
import AboutBox from "../common/about";
import { fetcher } from "../../common/fetch";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { countryCode } from "../../../scripts/country";
import Footer from "../common/footer";
import Buttons from "../common/buttons";
import AdsCard from "../../common/ad";

export default class CountryIndexZh extends React.Component<
  {
    inactive: boolean;
  },
  {
    countries: any[];
    loading: boolean;
  }
> {
  constructor(props: { inactive: boolean }) {
    super(props);
    this.state = {
      countries: [],
      loading: true,
    };
  }

  async componentDidMount() {
    try {
      const res = await fetcher("getPhoneByCountry", {
        active: 1,
      });
      const r = await res.json();
      if (!r.error) {
        let rt: any[] = [];
        let rts: any[][] = [];
        for (let i = 0; i < r.body.length; ++i) {
          rts.push(r.body[i]);
          if (i !== 0 && i % 4 === 0) {
            rt.push(rts);
            rts = [];
          }
        }
        this.setState({
          countries: rt,
          loading: false,
        });
      } else {
        throw new Error("e");
      }
    } catch (e) {
      this.setState({
        countries: [],
        loading: false,
      });
    }
  }

  render() {
    const { countries, loading } = this.state;
    window.document.title =
      "按国家显示电话号码 - ReceiveSMSForFree | 免费使用短信服务";
    return (
      <Container>
        <Typography variant="h3" component="h1" align="center">
          ReceiveSMS
          <br />
          ForFree
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom align="center">
          免费使用短信服务
        </Typography>
        <Buttons />
        <Typography variant="h6" component="h6" style={{ textAlign: "center" }}>
          按国家显示短信号码
        </Typography>
        {!loading &&
          countries.map((item: any) => {
            return (
              <div>
                <AdsCard />
                <Grid
                  container
                  style={{ margin: "20px 0" }}
                  justify="space-between"
                >
                  {item.map((_item: any) => {
                    return (
                      <Grid item xs={12} sm={6} md={4}>
                        <Card
                          key={item.country}
                          style={{
                            width: "100%",
                            background: "transparent",
                            boxShadow: "0 0 0 0",
                          }}
                        >
                          <CardContent>
                            <Grid container alignItems={"center"}>
                              <Grid item xs={3}>
                                <span
                                  style={{ fontSize: "2rem" }}
                                  className={
                                    "flag-icon flag-icon-" +
                                    countryCode(_item.country)
                                  }
                                ></span>
                              </Grid>
                              <Grid item xs={9}>
                                <Typography color="textSecondary" gutterBottom>
                                  {_item.country}
                                </Typography>
                                <Typography variant="h5" component="h2">
                                  <Link
                                    component={RefLink}
                                    to={"/country/" + _item.country}
                                  >
                                    {_item.SUM} 个号
                                  </Link>
                                </Typography>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            );
          })}
        {loading && (
          <div>
            <CircularProgress />
          </div>
        )}
        <Grid container>
          <Grid
            xs={12}
            sm={12}
            md={6}
            item
            style={{ margin: "10px 0", padding: "10px" }}
          >
            <Typography variant="h5" component="h3" gutterBottom>
              为什么要为每个国家/地区选择一个电话号码？
            </Typography>
            <p>
              某些服务可能仅在某些国家/地区接受电话号码。
              <br />
              我们努力确保在尽可能多的国家/地区提供电话号码。
              <br />
              如果您所在国家的电话号码未列出，请随时与我们联系。
            </p>
            <p>
              我们的电子邮件地址是sms-online-contact@protonmail.com。
              <br />
              请向我们发送您的消息。
            </p>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={6}
            item
            style={{ margin: "10px 0", padding: "10px" }}
          >
            <Typography variant="h5" component="h3" gutterBottom>
              有问题吗？
            </Typography>
            <p>
              如果您对我们的服务有疑问，请随时与我们联系（sms-online-contact@protonmail.com）。
            </p>
            <p>
              或者，
              <Link component={RefLink} to={"/zh/faq/"}>
                请参阅常见问题页面
              </Link>
              。
            </p>
          </Grid>
        </Grid>
        <AboutBox />
        <Footer />
      </Container>
    );
  }
}
