import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import HomeIcon from "@material-ui/icons/Home";
import { RefLink } from "../../common/anchor";
import Link from "@material-ui/core/Link";
import Footer from "../common/footer";
import { RouteComponentProps } from "react-router-dom";
import { fetcher } from "../../common/fetch";
import CircularProgress from "@material-ui/core/CircularProgress";
import AdsCard from "../../common/ad";

export default class ColumnJa extends React.Component<
  RouteComponentProps,
  {
    result: any[];
    loading: boolean;
  }
> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      result: [],
      loading: true,
    };
  }

  async componentDidMount() {
    try {
      const res = await fetcher("getColumn", {
        lang: "ja",
        title: (this.props.match.params as any).code || "",
      });
      const r = await res.json();
      if (!r.error) {
        this.setState({
          result: r.body,
          loading: false,
        });
      } else {
        throw new Error("e");
      }
    } catch (e) {
      console.log(e);
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    const { loading, result } = this.state;
    window.document.title =
      " - ReceiveSMSForFree | 無料で使える使い捨てSMSサービス";
    if (loading) {
      return (
        <div>
          <CircularProgress />
        </div>
      );
    }
    return (
      <Container>
        <Link
          component={RefLink}
          to={"/ja/"}
          style={{ marginBottom: "10px", display: "block" }}
        >
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            startIcon={<HomeIcon />}
          >
            Back to Top
          </Button>
        </Link>
        {result.map((item, _i) => {
          window.document.title =
            item.displayTitle +
            " - ReceiveSMSForFree | 無料で使える使い捨てSMSサービス";
          return (
            <div>
              <Typography variant="h3" component="h1" align="center">
                {item.displayTitle}
              </Typography>
              <AdsCard />
              <div dangerouslySetInnerHTML={{ __html: item.body }}></div>
              <AdsCard />
            </div>
          );
        })}
        <Footer />
      </Container>
    );
  }
}
