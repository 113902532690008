import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import HomeIcon from "@material-ui/icons/Home";
import { RefLink } from "../../common/anchor";
import Link from "@material-ui/core/Link";
import Footer from "../common/footer";

export default class Column extends React.Component<{}, {}> {
  render() {
    window.document.title =
      "お問い合わせ - ReceiveSMSForFree | 無料で使える使い捨てSMSサービス";
    return (
      <Container>
        <Link
          component={RefLink}
          to={"/ja/"}
          style={{ marginBottom: "10px", display: "block" }}
        >
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            startIcon={<HomeIcon />}
          >
            Back to Top
          </Button>
        </Link>
        <Typography variant="h3" component="h1" align="center">
          ReceiveSMS
          <br />
          ForFree
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom align="center">
          お問い合わせ
        </Typography>
        <p>
          私達はメールでのみお問い合わせに対応しています。
          <br />
          なにか問題がある場合には、sms-online-contact@protonmail.comまでお問い合わせください。
        </p>
        <p>
          お問い合わせに返信するまで、2日ほど時間がかかる場合があります。
          <br />
          私達は英語・中国語・日本語のメッセージのみ対応可能です。
        </p>
        <Typography variant="h5" component="h3" gutterBottom>
          To investigators
        </Typography>
        <p>
          If you have any questions related to the illegal production and use of
          an electromagnetic record, please contact us at the above e-mail
          address.
        </p>
        <Footer />
      </Container>
    );
  }
}
