import moment, { Moment } from "moment";

export const fetcher = async (url: string, query: any) => {
  return await fetch("https://api.receive-sms-for-free.com/" + url, {
    //return await fetch("http://localhost/b/api/" + url + ".php",{
    method: "POST",
    mode: "cors",
    body: JSON.stringify(query),
  });
};

export const timeDiff = (time: Moment) => {
  if (moment().diff(time, "day") === 0) {
    if (moment().diff(time, "hour") === 0) {
      return moment().diff(time, "minute") + " minutes ago";
    }
    return moment().diff(time, "hour") + " hours ago";
  }
  return moment().diff(time, "day") + " days ago";
};
