import { RouteComponentProps, withRouter } from "react-router-dom";
import React from "react";


class ScrollToTop extends React.Component<RouteComponentProps,{}> {
  componentDidUpdate(prevProps:any) {
    if (
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return null;
  }
}

export default withRouter(ScrollToTop);
