import React from "react";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import PhoneCard from "../common/list";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { RefLink } from "../../common/anchor";
import Link from "@material-ui/core/Link";
import AboutBox from "../common/about";
import { fetcher } from "../../common/fetch";
import Footer from "../common/footer";
import { readytoreceive } from "../common/texts";

export default class Inactive extends React.Component<
  {
    inactive: boolean;
  },
  {
    phones: any[];
    loading: boolean;
  }
> {
  constructor(props: { inactive: boolean }) {
    super(props);
    this.state = {
      phones: [],
      loading: true,
    };
  }

  async componentDidMount() {
    try {
      const res = await fetcher("getPhoneList", {
        active: 0,
      });
      const r = await res.json();
      if (!r.error) {
        this.setState({
          phones: r.body,
          loading: false,
        });
      } else {
        throw new Error("e");
      }
    } catch (e) {
      this.setState({
        phones: [],
        loading: false,
      });
    }
  }

  render() {
    const { phones, loading } = this.state;
    window.document.title =
      "Inactive Numbers - ReceiveSMSForFree | Free valid SMS Numbers" +
      readytoreceive;
    return (
      <Container>
        <Typography variant="h3" component="h1" align="center">
          ReceiveSMSForFree
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom align="center">
          Free valid SMS Numbers{readytoreceive}
        </Typography>
        <Link component={RefLink} to={"/"}>
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            style={{ marginBottom: "15px" }}
          >
            Active Numbers
          </Button>
        </Link>
        <Typography variant="h6" component="h6" style={{ textAlign: "center" }}>
          Archived numbers
        </Typography>
        {!loading && (
          <Grid container style={{ margin: "20px 0" }}>
            {phones.map((item, i) => {
              return (
                <Grid item xs={12} lg={6} md={6} key={i}>
                  <PhoneCard
                    number={item.number}
                    country={item.country}
                    added={item.added}
                    votes={item.votes}
                    upvote={item.upvote}
                    counter={item.counter}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
        {loading && (
          <div>
            <CircularProgress />
          </div>
        )}
        <Paper style={{ margin: "10px 0", padding: "10px" }}>
          <Typography variant="h5" component="h3" gutterBottom>
            Why there are "Archived Numbers"?
          </Typography>
          <p>
            We provide a lot of phone numbers to let as much people as possible
            use our services, but there are registration limits for each
            services for each phone numbers or potential risks that our phone
            numbers are judged as spam.
          </p>
          <p>
            We automatically detect whether our phone numbers are available or
            not, and when detected as unavailable we mark that number as
            archived.
          </p>
          <p>
            The archived numbers cannot be used anymore, but you can see the
            older SMS the number have received.
          </p>
        </Paper>
        <Paper style={{ margin: "10px 0", padding: "10px" }}>
          <Typography variant="h5" component="h3" gutterBottom>
            If you have been using archived numbers
          </Typography>
          <p>
            If you have been using an archived number, please unregister the
            number from services you are using, and then use new numbers that is
            available.
            <br />
            Archived numbers can no longer be used, so your SMS shortcodes are
            also unavailable.
            <br />
            We cannot answer any questions about messages sent to the archived
            number, so please change your phone number settings as soon as
            possible.
          </p>
        </Paper>
        <AboutBox />
        <Footer />
      </Container>
    );
  }
}
