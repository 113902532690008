import React from "react";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import PhoneCard from "../common/list";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { RefLink } from "../../common/anchor";
import Link from "@material-ui/core/Link";
import AboutBox from "../common/about";
import { fetcher } from "../../common/fetch";
import Footer from "../common/footer";

export default class InactiveZh extends React.Component<
  {
    inactive: boolean;
  },
  {
    phones: any[];
    loading: boolean;
  }
> {
  constructor(props: { inactive: boolean }) {
    super(props);
    this.state = {
      phones: [],
      loading: true,
    };
  }

  async componentDidMount() {
    try {
      const res = await fetcher("getPhoneList", {
        active: 0,
      });
      const r = await res.json();
      if (!r.error) {
        this.setState({
          phones: r.body,
          loading: false,
        });
      } else {
        throw new Error("e");
      }
    } catch (e) {
      this.setState({
        phones: [],
        loading: false,
      });
    }
  }

  render() {
    const { phones, loading } = this.state;
    window.document.title =
      "遗弃的电话号码 - ReceiveSMSForFree | 免费使用短信服务";
    return (
      <Container>
        <Typography variant="h3" component="h1" align="center">
          ReceiveSMS
          <br />
          ForFree
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom align="center">
          免费使用短信服务
        </Typography>
        <Link component={RefLink} to={"/zh/"}>
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            style={{ marginBottom: "15px" }}
          >
            查找可用的电话号码
          </Button>
        </Link>
        <Typography variant="h6" component="h6" style={{ textAlign: "center" }}>
          遗弃电话号码列表
        </Typography>
        {!loading && (
          <Grid container style={{ margin: "20px 0" }}>
            {phones.map((item, i) => {
              return (
                <Grid item xs={12} lg={6} md={6} key={i}>
                  <PhoneCard
                    number={item.number}
                    country={item.country}
                    added={item.added}
                    votes={item.votes}
                    upvote={item.upvote}
                    counter={item.counter}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
        {loading && (
          <div>
            <CircularProgress />
          </div>
        )}
        <Paper style={{ margin: "10px 0", padding: "15px" }}>
          <Typography variant="h5" component="h3" gutterBottom>
            为什么会有“过时的电话号码”？
          </Typography>
          <p>
            我们保留了大量电话号码，以便尽可能多的人可以使用我们的服务。
            但是，每个电话号码可以注册一项服务的次数是有限制的，并且存在通过在此站点上发布您的电话号码而被判定为垃圾邮件的风险。
          </p>
          <p>
            我们已经开发出一种机制来确定我们准备的电话号码是否具有很高的使用可能性。
            如果电话号码检查机制多次出现错误，您将自动移至“过时的电话号码”，并且您将无法再使用该电话号码。
          </p>
          <p>无法再使用电话号码，但仍可以确认到此为止收到的SMS。</p>
        </Paper>
        <Paper style={{ margin: "10px 0", padding: "15px" }}>
          <Typography variant="h5" component="h3" gutterBottom>
            如果您使用的电话号码过时
          </Typography>
          <p>
            如果您使用的电话号码过时，请将该电话号码与当前使用的服务断开链接，然后使用新的电话号码。
            <br />
            您将无法再收到新的SMS短代码，因为您的电话号码不再可用。
          </p>
          <p>
            请注意，我们无法回答有关过时的电话号码或发送给过时的电话号码的短消息的任何查询。
          </p>
        </Paper>
        <AboutBox />
        <Footer />
      </Container>
    );
  }
}
