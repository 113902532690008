import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import PhoneCard from "../common/list";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import HotelIcon from "@material-ui/icons/Hotel";
import { RefLink } from "../../common/anchor";
import Link from "@material-ui/core/Link";
import AboutBox from "../common/about";
import { fetcher } from "../../common/fetch";
import Footer from "../common/footer";
import Buttons from "../common/buttons";
import { readytoreceive } from "../common/texts";
import AdsCard from "../../common/ad";

export default class Index extends React.Component<
  {
    inactive: boolean;
  },
  {
    phones: any[];
    loading: boolean;
  }
> {
  constructor(props: { inactive: boolean }) {
    super(props);
    this.state = {
      phones: [],
      loading: true,
    };
  }

  async componentDidMount() {
    try {
      const res = await fetcher("getPhoneList", {
        active: 1,
      });
      const r = await res.json();
      if (!r.error) {
        this.setState({
          phones: r.body,
          loading: false,
        });
      } else {
        throw new Error("e");
      }
    } catch (e) {
      this.setState({
        phones: [],
        loading: false,
      });
    }
  }

  render() {
    window.document.title =
      "ReceiveSMSForFree | Free valid SMS Numbers" + readytoreceive;
    const { phones, loading } = this.state;
    return (
      <Container>
        <Typography variant="h3" component="h1" align="center">
          ReceiveSMS
          <br />
          ForFree
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom align="center">
          Free valid SMS Numbers{readytoreceive}
        </Typography>
        <Buttons />
        <AdsCard />
        <Typography variant="h6" component="h6" style={{ textAlign: "center" }}>
          LATEST AVAILABLE NUMBERS
        </Typography>
        {!loading && (
          <Grid container style={{ margin: "20px 0" }}>
            {phones.map((item, i) => {
              return (
                <Grid item xs={12} lg={6} md={6} key={i}>
                  <PhoneCard
                    number={item.number}
                    country={item.country}
                    added={item.added}
                    votes={item.votes}
                    upvote={item.upvote}
                    counter={item.counter}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
        {loading && (
          <div>
            <CircularProgress />
          </div>
        )}
        <Link component={RefLink} to={"/inactive_numbers/"}>
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            startIcon={<HotelIcon />}
          >
            Inactive Numbers
          </Button>
        </Link>
        <Grid container style={{ padding: "10px", margin: "10px 0" }}>
          <Grid item xs={12} lg={6}>
            <Typography variant="h5" component="h3" gutterBottom>
              Not want to buy any phone numbers?
            </Typography>
            <p>
              Okay, just use our phone numbers!
              <br />
              Our services are 100% free of charge.
            </p>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography variant="h5" component="h3" gutterBottom>
              Keep your privacy SAFE
            </Typography>
            <p>
              You don't want to expose your own phone number on the Internet?
              <br />
              Then, just use our web service. You can use phone numbers instead
              of your actual number.
              <br />
              Nowadays, it becomes more and more informational society and your
              information is being sold without knowing.
              <br />
              We do not ask for any your personal data.
            </p>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography variant="h5" component="h3" gutterBottom>
              Avoid Annoying SMS Verifications
            </Typography>
            <p>
              Our services have performances of bypassing SMS authentications
              such as Twitter,Google,Facebook,Instagram,WeChat,AliPay and etc.
              <br />
              We are adding new phone numbers on weekly basis so you can use our
              service stably.
            </p>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography variant="h5" component="h3" gutterBottom>
              FAST and STABLE
            </Typography>
            <p>
              Our services have 99.9% availability.
              <br />
              All systems for delivering your messages are completely
              automatized so you can see your messages in 5 minutes.
            </p>
          </Grid>
        </Grid>
        <AboutBox />
        <Footer />
      </Container>
    );
  }
}
