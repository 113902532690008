import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { RefLink } from "../../common/anchor";

export default class AboutBox extends React.Component<{},{}>{

  render(){
    return (
    <Paper style={{padding:"10px",margin:"10px 0"}}>
      <Typography variant="h5" component="h3" gutterBottom>
        Who are we?
      </Typography>
      <Grid container>
        <Grid item xs={12} lg={4}>
          <img src="/img/us.webp" alt="about us" style={{width:"100%",padding:"20px"}}/>
        </Grid>
        <Grid xs={12} lg={8}>
          <p>We are a group of 6 students at Shanghai Jiao Tong University(SJTU) from USA, Japan, India and China.<br/>
            We are researching the scalability of mobile phone networks and this project is part of that research.</p>
          <p>We anonymize all data and use it for statistical purposes, but we do not completely care about the contents.</p>
          <p><Link component={RefLink} to={"/about/"}>To see more information about this service, please click here.</Link></p>
        </Grid>
      </Grid>
    </Paper>
    );
  }
}
