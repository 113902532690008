import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { RefLink } from "../../common/anchor";

export default class AboutBox extends React.Component<{}, {}> {
  render() {
    return (
      <div>
        <Typography variant="h5" component="h3" gutterBottom>
          私達について
        </Typography>
        <Grid container>
          <Grid item xs={12} lg={4}>
            <img
              src="/img/us.webp"
              alt="about us"
              style={{ width: "100%", padding: "20px" }}
            />
          </Grid>
          <Grid xs={12} lg={8}>
            <p>
              私達は上海交通大学の学生6人によるチームです。米国人、日本人、インド人、中国人のメンバーから構成されています。
              <br />
              私達はモバイルネットワークの拡張性について研究を進めており、このReceiveSMSForFreeはその研究の一部です。
            </p>
            <p>
              私達は、本サービスを通じて交信されたSMSの内容を匿名化し、個人を識別できない統計データとして利用することがありますが、SMSの内容については一切関知しません。
              <br />
              安心して本サービスをお使いください。
              <br />
              *本サービスを中国の法律およびあなたが居住する国家の法律に違反する形でご利用はお控えください。
            </p>
            <p>
              <Link component={RefLink} to={"/ja/about/"}>
                このサービスについて詳しい情報を見る場合はこちらをクリックしてください。
              </Link>
            </p>
          </Grid>
        </Grid>
      </div>
    );
  }
}
