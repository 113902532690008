import * as React from "react";
import Router from "./route/";
import {
  createMuiTheme,
  ThemeOptions,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";

import ja from "./i18n/ja";
import en from "./i18n/en";
import zh from "./i18n/zh";

const styles = (_theme: any) => ({
  "@global": {
    typography: {
      fontSize: "3rem",
      h1: {
        fontSize: "3rem",
      },
      h2: {
        fontSize: "2rem",
      },
      h3: {
        fontSize: "1.5rem",
      },
    },
  },
});

export const themeOptions: ThemeOptions = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#ffb7b7",
      dark: "#464545",
    },
    secondary: {
      main: "#ff86b6",
    },
    text: {
      primary: "#ffffff",
    },
    background: {
      default: "#121212",
    },
  },
  spacing: 8,
  props: {
    MuiList: {
      dense: true,
    },
    MuiMenuItem: {
      dense: true,
    },
    MuiTable: {
      size: "small",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={themeOptions}>
      <Router />
    </ThemeProvider>
  );
}

export default withStyles(styles)(App);
